<template>
  <b-card-actions
    title="Meal"
    :amount="mealDeductAmount"
    :currency="currency"
    :collapsed="true"
    action-collapse
  >

    <b-row class="mb-5">
      <b-col
        md="4"
        lg="4"
        xs="6"
        :class="{ 'disabled': payslip && payslip.status === 'finally_generated' }"
      >
        <h5 class="text-capitalize">
          Meal Cost
        </h5>
        <b-form-input
          id="meal_amount_value"
          v-model="mealAmount"
          type="number"
          :disabled="payslip?.status === 'finally_generated'"
          placeholder="Meal Cost Per Person"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="4"
        lg="4"
        xs="6"
        :class="{ 'disabled': payslip && payslip.status === 'finally_generated' }"
      >
        <div>
          <b-card-text class="mb-0">
            {{ typeStatus }}
          </b-card-text>

          <b-form-checkbox
            :checked="isPolicySwitch"
            class="custom-control-primary"
            name="check-button"
            switch
            :disabled="payslip?.status === 'finally_generated'"
            @change="typeChange"
          />
        </div>

        <template v-if="!isPolicySwitch">
          <h5
              v-if="typeStatus === 'Manual'"
              class="text-capitalize"
              style="margin-top: 20px"
          >
            Edit Meal Amount
          </h5>
          <template>
            <div>
              <b-form-input
                id="manual_amount_value"
                v-model="mealDeductAmount"
                type="number"
                placeholder="Meal Amount"
                :disabled="payslip?.status === 'finally_generated'"
              />
            </div>
          </template>

          <h5
            id="meal"
            class="text-capitalize"
            style="margin-top: 20px"
          >
            Meal Remarks <span class="text-danger">*</span>
          </h5>
          <template>
            <div>
              <ValidationProvider
                  v-slot="{ errors }"
                  name="remarks"
                  vid="remarks"
                  :rules="!isPolicySwitch ? 'required' : ''"
              >
                <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="Remarks"
                    :disabled="payslip?.status === 'finally_generated'"
                    rows="1"
                />
              </ValidationProvider>
            </div>
          </template>
        </template>

        <h5
          v-if="typeStatus === 'Policy'"
          class="text-capitalize"
          style="margin-top: 20px"
        >
          Meal Policy
        </h5>
        <template v-if="isPolicySwitch">
          <div>
            <v-select
              id="policyId"
              v-model="policyId"
              :options="policyOptions"
              :reduce="(option) => option.id"
              label="name"
              :disabled="payslip?.status === 'finally_generated'"
              placeholder="Choose Here"
            />
          </div>
        </template>


      </b-col>

      <b-col
        v-if="typeStatus === 'Manual'"
        md="4"
        lg="4"
        xs="5"
      />

      <b-col
        v-else
        md="4"
        lg="4"
        xs="5"
      >

        <template v-if="Object.keys(policyDetailsData).length > 0">
          <h5 class="text-capitalize">
            Meal Policy
          </h5>
          <h6
            class="text-capitalize"
            style="display: inline"
          >
            Details:
          </h6>
          <p style="display: inline">
            <span v-if="policyDetailsData?.amount_value_type === 'percentage'">
              {{ mealAmount }} * {{ policyDetailsData?.amount_value }}%
            </span>
            <span v-if="policyDetailsData?.amount_value_type === 'flat'">
              {{ policyDetailsData?.amount_value }}
            </span>
          </p>
        </template>
      </b-col>

      <b-col
        offset="1"
        md="3"
        lg="3"
        xs="6"
        class="align-content-end"
      >
        <h5 class="text-capitalize">
          Meal Deduct Amount
        </h5>
        <template>
          <div>
            <b-form-input
              id="meal_deduct_value"
              v-model="mealDeductAmount"
              type="number"
              placeholder=""
              :disabled="true"
            />
          </div>
        </template>
      </b-col>
    </b-row>

  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCardText, BCol, BFormCheckbox, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'MealInfo',
  components: {
    ValidationProvider,
    BCardText,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    BCol,
    BFormInput,
    BCardActions,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mealAmount: 0,
      mealDeductAmount: 0,
      typeStatus: 'Policy',
      isPolicySwitch: true,
      policyChangeCount: 0,
      policyOptions: [],
      remarks: '',
      policyId: '',
      policyDetailsData: '',
    }
  },
  watch: {
    policyId() {
      this.preUpdateMealPolicy()
      this.emitValue()

      if (this.policyChangeCount > 0) {
        this.remarks = ''
      } else {
        this.policyChangeCount++
      }
    },
    remarks() {
      this.emitValue()
    },
    isPolicySwitch() {
      this.emitValue()
    },
    mealAmount() {
      this.preUpdateMealPolicy()
      this.emitValue()
    },
    mealDeductAmount() {
      this.emitValue()
    },
  },
  async created() {
    try {
      await this.loadItems()
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },
  methods: {
    emitValue() {
      this.$emit('append-policy-data', this.policyId)
      this.$emit('append-remarks-data', this.remarks)
      this.$emit('append-isPolicy-data', this.isPolicySwitch)
      this.$emit('append-manual-data', this.mealDeductAmount)
      this.$emit('append-meal-data', this.mealAmount)
    },
    typeChange() {
      this.isPolicySwitch = !this.isPolicySwitch
      this.mealDeductAmount = 0
      if (this.typeStatus === 'Policy') {
        this.typeStatus = 'Manual'
      } else {
        this.typeStatus = 'Policy'
        this.policyId = this.payslip.meal_policy_id
      }
    },
    async loadItems() {
      try {
        const [mealPolicies] = await Promise.all([
          this.getMealPolicyItems(),
        ])

        this.mealDeductAmount = this.payslip?.meal_amount
        this.policyId = this.payslip.meal_policy_id
        this.mealAmount = parseInt(this.payslip?.meal_cost)

        this.remarks = this.payslip?.meal_remarks

        if (this.remarks) {
          this.typeStatus = 'Manual'
          this.isPolicySwitch = false
        }

        // after update remark don't go
        this.policyChangeCount = 0

        this.policyOptions = (
            mealPolicies?.data?.data || []
        ).map(item => ({
          name: item.title,
          id: item.id,
        }))
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    async getMealPolicyItems() {
      return this.$api.get('api/meal-policy/all')
    },

    async preUpdateMealPolicy() {
      if (this.policyId && this.mealAmount > 0) {
        const response = await this.$api.put(
          `/api/payroll/${this.$route.params.userId}/meal-policy/pre-update-calculation`,
          {
            policy_id: this.policyId,
            payslip_id: this.payslip?.id,
            meal_amount: parseInt(this.mealAmount),
          },
        )

        this.mealDeductAmount = parseInt(response?.data?.total_amount?.original?.total_amount)
        this.policyDetailsData = response?.data?.total_amount?.original?.data
      }
    },
  },
}
</script>

<style scoped>
</style>
