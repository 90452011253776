<template>
  <b-card-actions
    title="Extra Leave"
    :amount="extraLeaveAmount"
    :currency="currency"
    :collapsed="true"
    action-collapse
  >
    <b-row>
      <b-col
        md="4"
        lg="4"
        xs="6"
        :class="{ 'disabled': payslip && payslip.status === 'finally_generated' }"
      >
        <div>
          <b-card-text class="mb-0">
            {{ typeStatus }}
          </b-card-text>

          <b-form-checkbox
            :checked="isManualSwitch"
            class="custom-control-primary"
            name="check-button"
            switch
            :disabled="payslip?.status === 'finally_generated'"
            @change="typeChange"
          />
        </div>

        <h5
          class="text-capitalize"
          style="margin-top: 20px"
        >
          Edit Amount
        </h5>
        <template>
          <div>
            <b-form-input
              id="extra_leave_amount_value"
              v-model="extraLeaveAmount"
              type="number"
              placeholder="Extra Leave Amount"
              :disabled="!isManualSwitch || payslip?.status === 'finally_generated'"
            />
          </div>
        </template>

        <template v-if="isManualSwitch">
          <h5
            id="unpaid_remarks"
            class="text-capitalize"
            style="margin-top: 20px"
          >
            Extra Leave Remarks <span class="text-danger">*</span>
          </h5>
          <div>
            <ValidationProvider
              v-slot="{ errors }"
              name="remarks"
              vid="remarks"
              :rules="isManualSwitch ? 'required' : ''"
            >
              <b-form-textarea
                id="remarks"
                v-model="remarks"
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="Remarks"
                rows="1"
                :disabled="payslip?.status === 'finally_generated'"
              />
            </ValidationProvider>
          </div>
        </template>
      </b-col>

      <b-col
        v-if="isManualSwitch"
        md="4"
        lg="4"
        xs="5"
      />

      <b-col
        v-else
        md="4"
        lg="4"
        xs="5"
      >
        <template>
          <h5 class="text-capitalize">
            Extra Leave Days Count: {{ payslip?.extra_leave_day_count }}
          </h5>
        </template>
      </b-col>

      <b-col
        offset="1"
        md="3"
        lg="3"
        xs="6"
      >
        <h5 class="text-capitalize">
          Extra Leave Fine Amount
        </h5>
        <template>
          <div>
            <b-form-input
              id="extra_leave_amount_value"
              v-model="extraLeaveAmount"
              type="number"
              placeholder=""
              :disabled="true"
            />
          </div>
        </template>
      </b-col>
    </b-row>

  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCardText, BCol, BFormCheckbox, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ExtraLeaveInfo',
  components: {
    ValidationProvider,
    BCardText,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    BCol,
    BFormInput,
    BCardActions,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      extraLeaveAmount: 0,
      typeStatus: 'Manual',
      isManualSwitch: false,
      remarks: '',
    }
  },
  watch: {
    payslip() {
      this.setExtraLeaveAmount()
    },
    remarks() {
      this.emitValue()
    },
    isManualSwitch() {
      this.emitValue()
    },
    extraLeaveAmount(newVal) {
      this.overTimeSalaryAmount = newVal
      this.emitValue()
    },
  },
  methods: {
    emitValue() {
      this.$emit('append-remarks-data', this.remarks)
      this.$emit('append-isManual-data', this.isManualSwitch)
      this.$emit('append-manual-data', this.extraLeaveAmount)
    },
    setExtraLeaveAmount() {
      this.extraLeaveAmount = this.payslip?.extra_leave_fine_amount
      this.remarks = this.payslip?.extra_leave_remarks

      if (this.remarks) {
        this.isManualSwitch = true
      }
    },
    typeChange() {
      console.log('hello')
      this.isManualSwitch = !this.isManualSwitch
      if (this.isManualSwitch) {
        this.extraLeaveAmount = this.payslip?.extra_leave_fine_amount || 0
      }
    },
  },
}
</script>

<style scoped>
</style>
