var render = function () {
  var _vm$payslip, _vm$payslip4, _vm$policyDetailsData, _vm$policyDetailsData2, _vm$policyDetailsData3, _vm$policyDetailsData4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Late Fine",
      "amount": _vm.LateFineAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status === 'finally_generated'
    },
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "6"
    }
  }, [_c('div', [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.typeStatus) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": _vm.isPolicySwitch,
      "name": "check-button",
      "switch": "",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated'
    },
    on: {
      "change": _vm.typeChange
    }
  })], 1), _vm.typeStatus === 'Manual' ? _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Edit Late Fine Amount ")]) : _vm._e(), !_vm.isPolicySwitch ? [_c('div', [_c('ValidationProvider', {
    attrs: {
      "name": "Late Fine amount",
      "vid": "manual_amount_value",
      "rules": "numeric_or_float"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$payslip2;
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "manual_amount_value",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Late Fine Amount",
            "disabled": ((_vm$payslip2 = _vm.payslip) === null || _vm$payslip2 === void 0 ? void 0 : _vm$payslip2.status) === 'finally_generated'
          },
          model: {
            value: _vm.LateFineAmount,
            callback: function callback($$v) {
              _vm.LateFineAmount = $$v;
            },
            expression: "LateFineAmount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1334996408)
  })], 1), _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "id": "lateFine"
    }
  }, [_vm._v(" Late Fine Remarks "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), [_c('div', [_c('ValidationProvider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": !_vm.isPolicySwitch ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _vm$payslip3;
        var errors = _ref2.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Remarks",
            "disabled": ((_vm$payslip3 = _vm.payslip) === null || _vm$payslip3 === void 0 ? void 0 : _vm$payslip3.status) === 'finally_generated',
            "rows": "1"
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        })];
      }
    }], null, false, 2714636692)
  })], 1)]] : _vm._e(), _vm.typeStatus === 'Policy' ? _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Late Fine Policy ")]) : _vm._e(), _vm.typeStatus === 'Policy' ? [_c('div', [_c('v-select', {
    attrs: {
      "id": "lateFinePolicyId",
      "options": _vm.lateFinePolicyOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "placeholder": "Choose Here",
      "disabled": ((_vm$payslip4 = _vm.payslip) === null || _vm$payslip4 === void 0 ? void 0 : _vm$payslip4.status) === 'finally_generated',
      "multiple": ""
    },
    model: {
      value: _vm.lateFinePolicyId,
      callback: function callback($$v) {
        _vm.lateFinePolicyId = $$v;
      },
      expression: "lateFinePolicyId"
    }
  })], 1)] : _vm._e()], 2), _vm.typeStatus === 'Manual' ? _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }) : _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Late Fine Policy Details ")]), (_vm$policyDetailsData = _vm.policyDetailsData) !== null && _vm$policyDetailsData !== void 0 && _vm$policyDetailsData.delay ? _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Delay Fine: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$policyDetailsData2 = _vm.policyDetailsData) === null || _vm$policyDetailsData2 === void 0 ? void 0 : _vm$policyDetailsData2.delay) + " ")])]) : _vm._e(), (_vm$policyDetailsData3 = _vm.policyDetailsData) !== null && _vm$policyDetailsData3 !== void 0 && _vm$policyDetailsData3.extreme_delay ? _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Extreme Delay Fine: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$policyDetailsData4 = _vm.policyDetailsData) === null || _vm$policyDetailsData4 === void 0 ? void 0 : _vm$policyDetailsData4.extreme_delay) + " ")])]) : _vm._e()]), _c('b-col', {
    staticClass: "align-content-end",
    attrs: {
      "offset": "1",
      "md": "3",
      "lg": "3",
      "xs": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Late Fine Amount ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "manual_amount_value",
      "type": "number",
      "placeholder": "Late Fine",
      "disabled": true
    },
    model: {
      value: _vm.LateFineAmount,
      callback: function callback($$v) {
        _vm.LateFineAmount = $$v;
      },
      expression: "LateFineAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }