var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Income Tax",
      "amount": _vm.incomeTaxAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status === 'finally_generated'
    },
    attrs: {
      "cols": "4"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Income Tax Amount Input ")]), [_c('div', [_c('ValidationProvider', {
    attrs: {
      "name": "Tax amount",
      "vid": "tax_amount",
      "rules": "required|min:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$payslip;
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "income_tax_amount",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated',
            "placeholder": "Tax deduction amount"
          },
          model: {
            value: _vm.incomeTaxAmount,
            callback: function callback($$v) {
              _vm.incomeTaxAmount = $$v;
            },
            expression: "incomeTaxAmount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)]], 2), _c('b-col', {
    attrs: {
      "offset": "5",
      "cols": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Income Tax Amount ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "tax_deduction_amount",
      "type": "number",
      "placeholder": "Tax deduction amount",
      "disabled": true
    },
    model: {
      value: _vm.incomeTaxAmount,
      callback: function callback($$v) {
        _vm.incomeTaxAmount = $$v;
      },
      expression: "incomeTaxAmount"
    }
  })], 1)]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }