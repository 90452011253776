<template>
  <b-card>
    <b-row>
      <b-col md="10">
        <h4 class="mb-2">
          User Info
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <div class="col-md-3 col-lg-3">
        <b-avatar :src="userData?.avatar" size="104px" rounded />
      </div>

      <div class="col-md-3 col-lg-3">
        <h5 class="text-capitalize mb-75">
          Name
        </h5>
        <p>{{ userData?.name }}</p>

        <h5 class="text-capitalize mb-75">
          Email
        </h5>
        <p>{{ userData?.email }}</p>

        <h5 class="text-capitalize mb-75">
          Mobile
        </h5>
        <p>{{ userData?.mobile }}</p>
      </div>

      <div class="col-md-3 col-lg-3">
        <h5 class="text-capitalize mb-75">
          Department
        </h5>
        <p>{{ userData?.department?.data?.name }}</p>

        <h5 class="text-capitalize mb-75">
          Gross Salary
        </h5>
        <p class="mb-2">
          {{ currency }} {{ payslip.gross_salary }}
        </p>

        <h5
          id="basic_salary"
          class="text-capitalize mb-75"
        >
          Total Payable Amount
        </h5>
        <p class="mb-2">
          {{ currency }} {{ payslip.total_payable_amount }}
        </p>
      </div>

      <div class="col-md-3 col-lg-3">
        <h5 class="text-capitalize mb-75">
          Payslip Date
        </h5>
        <p>{{ formatDate(payslip.payslip_date) }}</p>

        <h5 class="text-capitalize mb-75">
          Payslip Status
        </h5>
        <p>
          <b-badge
            v-if="payslip?.status === 'initially_generated'"
            variant="light-warning"
            class="mb-1"
          >
            Initially Generated
          </b-badge>
          <b-badge
            v-else
            variant="light-success"
            class="mb-1"
          >
            Finally Generated
          </b-badge>
        </p>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'UserDetailsCard',
  components: {
    BAvatar,
    BBadge,
    BCol,
    BRow,
    BCard,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
</style>
