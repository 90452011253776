var render = function () {
  var _vm$insurancePolicyDe, _vm$insurancePolicyDe2, _vm$insurancePolicyDe3, _vm$insurancePolicyDe4, _vm$insurancePolicyDe5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Insurance",
      "currency": _vm.currency,
      "amount": _vm.insuranceAmount,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "4"
    }
  }), _c('b-col', {
    attrs: {
      "md": "5",
      "lg": "5",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Insurance Policy Details")]), _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v("Title:")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$insurancePolicyDe = _vm.insurancePolicyDetailsData) === null || _vm$insurancePolicyDe === void 0 ? void 0 : _vm$insurancePolicyDe.title) + " ")])]), ((_vm$insurancePolicyDe2 = _vm.insurancePolicyDetailsData) === null || _vm$insurancePolicyDe2 === void 0 ? void 0 : _vm$insurancePolicyDe2.amount_value_type) == 'flat' ? _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Amount Value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$insurancePolicyDe3 = _vm.insurancePolicyDetailsData) === null || _vm$insurancePolicyDe3 === void 0 ? void 0 : _vm$insurancePolicyDe3.amount_value) + " ")])]) : _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Amount Value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$insurancePolicyDe4 = _vm.insurancePolicyDetailsData) === null || _vm$insurancePolicyDe4 === void 0 ? void 0 : _vm$insurancePolicyDe4.amount_value) + " % of gross salary ")])]), _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Total Months: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$insurancePolicyDe5 = _vm.insurancePolicyDetailsData) === null || _vm$insurancePolicyDe5 === void 0 ? void 0 : _vm$insurancePolicyDe5.month) + " ")])])]), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Insurance Amount")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "insurance_amount",
      "type": "number",
      "placeholder": "Insurance Amount",
      "disabled": true
    },
    model: {
      value: _vm.insuranceAmount,
      callback: function callback($$v) {
        _vm.insuranceAmount = $$v;
      },
      expression: "insuranceAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }