var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Basic Salary",
      "amount": _vm.BasicSalaryAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "4"
    }
  }), _c('b-col', {
    attrs: {
      "md": "5",
      "lg": "5",
      "xs": "5"
    }
  }), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Basic Salary")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "basic_salary",
      "type": "number",
      "placeholder": "Basic Salary Amount",
      "disabled": true
    },
    model: {
      value: _vm.BasicSalaryAmount,
      callback: function callback($$v) {
        _vm.BasicSalaryAmount = $$v;
      },
      expression: "BasicSalaryAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }