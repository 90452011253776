var render = function () {
  var _vm$providentPolicyDe, _vm$providentPolicyDe2, _vm$providentPolicyDe3, _vm$providentPolicyDe4, _vm$providentPolicyDe5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Provident Fund",
      "currency": _vm.currency,
      "amount": _vm.providentFundAmount,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "4"
    }
  }), _c('b-col', {
    attrs: {
      "md": "5",
      "lg": "5",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Provident Fund Policy Details")]), _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v("Title:")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$providentPolicyDe = _vm.providentPolicyDetailsData) === null || _vm$providentPolicyDe === void 0 ? void 0 : _vm$providentPolicyDe.title) + " ")])]), ((_vm$providentPolicyDe2 = _vm.providentPolicyDetailsData) === null || _vm$providentPolicyDe2 === void 0 ? void 0 : _vm$providentPolicyDe2.amount_value_type) == 'flat' ? _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Amount Value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$providentPolicyDe3 = _vm.providentPolicyDetailsData) === null || _vm$providentPolicyDe3 === void 0 ? void 0 : _vm$providentPolicyDe3.amount_value) + " ")])]) : _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Amount Value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$providentPolicyDe4 = _vm.providentPolicyDetailsData) === null || _vm$providentPolicyDe4 === void 0 ? void 0 : _vm$providentPolicyDe4.amount_value) + " % of gross salary ")])]), _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Total Month: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$providentPolicyDe5 = _vm.providentPolicyDetailsData) === null || _vm$providentPolicyDe5 === void 0 ? void 0 : _vm$providentPolicyDe5.month) + " ")])])]), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Provident Fund Amount")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "provident_fund_amount",
      "type": "number",
      "placeholder": "Provident Fund Amount",
      "disabled": true
    },
    model: {
      value: _vm.providentFundAmount,
      callback: function callback($$v) {
        _vm.providentFundAmount = $$v;
      },
      expression: "providentFundAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }