<template>

    <b-card-actions title="Overtime Salary" :amount="overTimeSalaryAmount" :currency="currency" :collapsed="true" action-collapse>
    <b-row>

      <b-col
        md="4"
        lg="4"
        xs="6"
        :class="{ 'disabled': payslip && payslip.status == 'finally_generated' }"
      >
        <div>
          <b-card-text class="mb-0">
            {{ oTSalaryInputStatus }}
          </b-card-text>

          <b-form-checkbox
            :checked="isPolicySwitch"
            class="custom-control-primary"
            name="check-button"
            switch
            :disabled="payslip?.status === 'finally_generated'"
            @change="(newVal) => onSwitchChange(newVal)"
          />
        </div>



        <h5
          v-if="oTSalaryInputStatus === 'Policy'"
          class="text-capitalize"
          style="margin-top: 20px"
        >
          OverTime Salary Policy
        </h5>
        <template v-if="oTSalaryInputStatus === 'Policy'">
          <div>
            <v-select
              id="policyId"
              v-model="overTimeSalaryPolicyId"
              :options="overTimeSalaryPolicyOptions"
              :reduce="(option) => option.id"
              label="name"
              placeholder="Choose Here"
              :disabled="payslip?.status === 'finally_generated'"
            />
          </div>
        </template>

        <template v-if="!isPolicySwitch">
          <h5
            v-if="oTSalaryInputStatus === 'Manual'"
            class="text-capitalize"
            style="margin-top: 20px"
          >
            Manual OverTime Salary
          </h5>
          <template v-if="oTSalaryInputStatus === 'Manual'">
            <div>
              <b-form-input
                id="working_day_amount_value"
                v-model="editOverTimeSalaryAmount"
                type="number"
                :disabled="payslip?.status === 'finally_generated'"
                placeholder="Overtime Amount"
              />
            </div>
          </template>

          <h5
            id="project_commission"
            class="text-capitalize"
            style="margin-top: 20px"
          >
            OverTime Salary Remarks <span class="text-danger">*</span>
          </h5>
          <template>
            <div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Overtime remarks"
                vid="ovtimeremarks"
                :rules="!isPolicySwitch ? 'required' : ''"
              >
                <b-form-textarea
                  id="remarks"
                  v-model="overTimeSalaryRemarksText"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Remarks"
                  :disabled="payslip?.status === 'finally_generated'"
                  rows="1"
                  class="required-label"
                />
              </ValidationProvider>
            </div>
          </template>
        </template>

      </b-col>

      <b-col
        v-if="oTSalaryInputStatus === 'Manual'"
        md="4"
        lg="4"
        xs="5"
      />

      <b-col
        v-else
        md="4"
        lg="4"
        xs="5"
      >

        <h5 class="text-capitalize mt-2">
          Overtime Salary Policy Details
        </h5>

        <div>
          <h6
            class="text-capitalize"
            style="display: inline"
          >
            Off day amount value:
          </h6>
          <p style="display: inline">

            <span v-if="overtimeSalaryPolicyDetailsData?.off_day_amount_value_type === 'multiplied'">
              {{ overtimeSalaryPolicyDetailsData?.off_day_amount_value }} * Hourly salary
            </span>
            <span v-else-if="overtimeSalaryPolicyDetailsData?.off_day_amount_value_type === 'extra_added_with_monthly_salary'">
              {{ overtimeSalaryPolicyDetailsData?.off_day_amount_value }} + Monthly salary
            </span>
            <span v-else-if="overtimeSalaryPolicyDetailsData?.off_day_amount_value_type === 'extra_added_with_hourly_rate'">
              {{ overtimeSalaryPolicyDetailsData?.off_day_amount_value }} + Hourly salary
            </span>
            <span v-else>
              {{ overtimeSalaryPolicyDetailsData?.off_day_amount_value }}
            </span>

          </p>
        </div>
        <div>
          <h6
            class="text-capitalize"
            style="display: inline"
          >
            Working day amount value:
          </h6>
          <p style="display: inline">

            <span v-if="overtimeSalaryPolicyDetailsData?.working_day_amount_value_type === 'multiplied'">
              {{ overtimeSalaryPolicyDetailsData?.working_day_amount_value }} * Hourly salary
            </span>
            <span v-else-if="overtimeSalaryPolicyDetailsData?.working_day_amount_value_type === 'extra_added_with_monthly_salary'">
              {{ overtimeSalaryPolicyDetailsData?.working_day_amount_value }} + Monthly salary
            </span>
            <span v-else-if="overtimeSalaryPolicyDetailsData?.working_day_amount_value_type === 'extra_added_with_hourly_rate'">
              {{ overtimeSalaryPolicyDetailsData?.working_day_amount_value }} + Hourly salary
            </span>
            <span v-else>
              {{ overtimeSalaryPolicyDetailsData?.working_day_amount_value }}
            </span>

          </p>
        </div>
      </b-col>

      <b-col
        offset="1"
        md="3"
        lg="3"
        xs="6"
      >
        <h5 class="text-capitalize">
          OverTime Salary
        </h5>
        <template>
          <div>
            <b-form-input
              id="working_day_amount_value"
              v-model="overTimeSalaryAmount"
              type="number"
              placeholder="Overtime Salary"
              :disabled="true"
            />
          </div>
        </template>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCardText, BCol, BFormCheckbox, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider } from 'vee-validate'
import {
  required,
  email,
  max,
  mimes,
  ext,
  size,
  confirmed,
} from '@validations'

export default {
  name: 'OverTimeSalaryInfo',
  components: {
    ValidationProvider,
    BFormInput,
    BFormTextarea,
    BCardText,
    BCol,
    BRow,
    BFormCheckbox,
    BCardActions,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      oTSalaryInputStatus: 'Policy',
      isPolicySwitch: true,
      salaryPolicyOptions: [],
      salaryChangeCount: 0,
      overTimeChangeCount: 0,
      overTimeSalaryAmount: 0,
      editOverTimeSalaryAmount: 0,
      overTimeSalaryPolicyId: '',
      overTimeSalaryPolicyOptions: [],
      overTimeSalaryRemarksText: '',
      overtimeSalaryDetailsData: '',
      overtimeSalaryPolicyDetailsData: '',
      finalGenerate: '',
    }
  },
  watch: {
    overTimeSalaryPolicyId() {
      this.preUpdateOverTimeSalaryPolicy()
      this.emitValue()

      if (this.overTimeChangeCount > 0) {
        this.overTimeSalaryRemarksText = ''
      } else {
        this.overTimeChangeCount++
      }
    },

    overTimeSalaryRemarksText() {
      this.emitValue()
    },
    isPolicySwitch() {
      this.emitValue()
    },
    editOverTimeSalaryAmount(newVal) {
      this.overTimeSalaryAmount = newVal
      this.emitValue()
    },
  },
  async created() {
    try {
      await this.loadItems()
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },
  methods: {
    emitValue() {
      this.$emit('append-policy-data', this.overTimeSalaryPolicyId)
      this.$emit('append-remarks-data', this.overTimeSalaryRemarksText)
      this.$emit('append-isPolicy-data', this.isPolicySwitch)
      this.$emit('append-manual-data', this.editOverTimeSalaryAmount)
    },
    onSwitchChange() {
      if (this.isPolicySwitch) {
        this.oTSalaryInputStatus = 'Manual'
        this.isPolicySwitch = false
      } else {
        this.isPolicySwitch = true
        this.oTSalaryInputStatus = 'Policy'
        this.editOverTimeSalaryAmount = ''
        this.overTimeSalaryPolicyId = this.payslip?.over_time_policy_id
        console.log(this.payslip?.over_time_policy_id)
      }
    },
    async loadItems() {
      try {
        const [overTimePolicies] = await Promise.all([
          this.getOverTimePolicyItems(),
        ])

        // Over time policy
        this.overTimeSalaryAmount = this.payslip?.over_time_amount
        this.overTimeSalaryRemarksText = this.payslip?.overtime_remarks

        if (this.overTimeSalaryRemarksText) {
          this.isPolicySwitch = false
          this.oTSalaryInputStatus = 'Manual'
          this.editOverTimeSalaryAmount = this.payslip?.over_time_amount
          this.overTimeSalaryAmount = this.payslip?.over_time_amount
        } else {

          if(this.payslip?.over_time_policy_id){
            this.overTimeSalaryPolicyId = parseInt(this.payslip?.over_time_policy_id)
          }

        }

        // after update remark don't go
        this.overTimeChangeCount = 0

        this.overTimeSalaryPolicyOptions = (
            overTimePolicies?.data?.data || []
        ).map(item => ({
          name: item.title,
          id: item.id,
        }))

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    async getOverTimePolicyItems() {
      return this.$api.get('api/over-time-policy/all')
    },

    async preUpdateOverTimeSalaryPolicy() {
      if (this.overTimeSalaryPolicyId) {
        const response = await this.$api.put(
          `/api/payroll/${this.$route.params.userId}/over-time-policy/pre-update-calculation`,
          {
            over_time_policy_id: this.overTimeSalaryPolicyId,
            payslip_id: this.payslip?.id,
            payslip_date: this.payslip?.payslip_date,
          },
        )

        this.overTimeSalaryAmount = response?.data?.total_amount?.original?.total_amount

        this.overtimeSalaryDetailsData = response?.data?.total_amount?.original
        this.overtimeSalaryPolicyDetailsData = response?.data?.total_amount?.original?.overtime_policy_data
      }
    },
  },
}
</script>

<style scoped>
.required-label label::after {
  content: " *";
  color: red;
}
</style>
