var render = function () {
  var _vm$payslip, _vm$payslip2, _vm$payslip3, _vm$projectCommission, _vm$projectCommission2, _vm$projectCommission3, _vm$payslip4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Project Commission",
      "amount": _vm.projectCommissionSalaryAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "4"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.projectCommissionStatus))]), _c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": _vm.switchValue,
      "name": "check-button",
      "switch": "",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated'
    },
    on: {
      "input": _vm.onSwitchChange
    }
  }), _vm.projectCommissionStatus == 'Policy' ? _c('div', [_c('h5', {
    staticClass: "text-capitalize mt-1"
  }, [_vm._v("Project Commission Policy")]), [_c('div', [_c('v-select', {
    attrs: {
      "id": "project_commission_policy",
      "options": _vm.projectCommissionPolicyOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "disabled": ((_vm$payslip2 = _vm.payslip) === null || _vm$payslip2 === void 0 ? void 0 : _vm$payslip2.status) === 'finally_generated',
      "placeholder": "Choose Here"
    },
    model: {
      value: _vm.projectCommissionPolicyId,
      callback: function callback($$v) {
        _vm.projectCommissionPolicyId = $$v;
      },
      expression: "projectCommissionPolicyId"
    }
  })], 1)]], 2) : _vm._e(), _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v("Milestones")]), [_c('div', [_c('v-select', {
    attrs: {
      "id": "user_id",
      "placeholder": "Choose Here",
      "options": _vm.milestoneCommissionSalaryOptions,
      "reduce": function reduce(item) {
        return item === null || item === void 0 ? void 0 : item.id;
      },
      "label": "name",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "multiple": "",
      "disabled": ((_vm$payslip3 = _vm.payslip) === null || _vm$payslip3 === void 0 ? void 0 : _vm$payslip3.status) === 'finally_generated'
    },
    on: {
      "input": function input($event) {
        return _vm.preUpdateProjectCommission(_vm.milestoneCommissionSalaryIds);
      }
    },
    model: {
      value: _vm.milestoneCommissionSalaryIds,
      callback: function callback($$v) {
        _vm.milestoneCommissionSalaryIds = $$v;
      },
      expression: "milestoneCommissionSalaryIds"
    }
  })], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "5",
      "lg": "5",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Project Commission Policy Details")]), ((_vm$projectCommission = _vm.projectCommissionPolicyDetailsData) === null || _vm$projectCommission === void 0 ? void 0 : _vm$projectCommission.amount_value_type) == 'flat' ? _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Amount value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$projectCommission2 = _vm.projectCommissionPolicyDetailsData) === null || _vm$projectCommission2 === void 0 ? void 0 : _vm$projectCommission2.amount_value) + " ")])]) : _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Amount value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" " + _vm._s((_vm$projectCommission3 = _vm.projectCommissionPolicyDetailsData) === null || _vm$projectCommission3 === void 0 ? void 0 : _vm$projectCommission3.amount_value) + "% ")])])]), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Project Commission Amount")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "project_commission_amount",
      "type": "number",
      "placeholder": "Project Commission Amount",
      "disabled": _vm.editProjectCommissionAmount
    },
    model: {
      value: _vm.projectCommissionSalaryAmount,
      callback: function callback($$v) {
        _vm.projectCommissionSalaryAmount = $$v;
      },
      expression: "projectCommissionSalaryAmount"
    }
  })], 1)], _vm.projectCommissionStatus == 'Manual' ? _c('div', {
    staticClass: "mt-1"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Manual Value Remarks "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), [_c('div', [_c('b-form-textarea', {
    attrs: {
      "id": "remarks",
      "type": "text",
      "placeholder": "Remarks",
      "disabled": ((_vm$payslip4 = _vm.payslip) === null || _vm$payslip4 === void 0 ? void 0 : _vm$payslip4.status) === 'finally_generated',
      "rows": "1"
    },
    model: {
      value: _vm.projectCommissionRemarks,
      callback: function callback($$v) {
        _vm.projectCommissionRemarks = $$v;
      },
      expression: "projectCommissionRemarks"
    }
  })], 1)]], 2) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }