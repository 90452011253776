var render = function () {
  var _vm$payslip;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Festival Bonus",
      "amount": _vm.festivalBonusPolicyAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status === 'finally_generated'
    },
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Festival Bonus Policies ")]), [_c('div', [_c('v-select', {
    attrs: {
      "id": "festival",
      "placeholder": "Search by festival bonus policies",
      "options": _vm.festivalBonusPolicyOptions,
      "reduce": function reduce(item) {
        return item === null || item === void 0 ? void 0 : item.id;
      },
      "label": "name",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "multiple": "",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated' || _vm.festivalBonusEligibilityStatus
    },
    model: {
      value: _vm.festivalBonusPolicyIds,
      callback: function callback($$v) {
        _vm.festivalBonusPolicyIds = $$v;
      },
      expression: "festivalBonusPolicyIds"
    }
  })], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Festival Bonus Policies Details ")]), _vm._l(_vm.festivalBonusPolicyData, function (value, index) {
    return _c('div', {
      key: index
    }, [_c('h6', {
      staticClass: "text-capitalize"
    }, [_vm._v(" Festival Bonus: " + _vm._s(index + 1) + " ")]), _c('h6', [_vm._v(" Title: "), _c('span', {
      staticClass: "font-resize"
    }, [_vm._v(_vm._s(value.title))])]), value.amount_value_type === 'flat' ? _c('h6', [_vm._v(" Amount Value: "), _c('span', {
      staticClass: "font-resize"
    }, [_vm._v(_vm._s(value.amount_value))])]) : _c('h6', [_vm._v(" Amount Value: "), _c('span', {
      staticClass: "font-resize"
    }, [_vm._v(_vm._s(value.amount_value) + "%")])])]);
  })], 2), _c('b-col', {
    attrs: {
      "offset": "1",
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Festival Bonus Amount ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "festival_bonus_policy_amount",
      "type": "number",
      "placeholder": "Festival Bonus Policy Amount",
      "disabled": true
    },
    model: {
      value: _vm.festivalBonusPolicyAmount,
      callback: function callback($$v) {
        _vm.festivalBonusPolicyAmount = $$v;
      },
      expression: "festivalBonusPolicyAmount"
    }
  })], 1)]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }