import { render, staticRenderFns } from "./OthersFine.vue?vue&type=template&id=3abe10fd&scoped=true&"
import script from "./OthersFine.vue?vue&type=script&lang=js&"
export * from "./OthersFine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3abe10fd",
  null
  
)

export default component.exports