<template>
  <b-card-actions title="Others Fine" :currency="currency" :amount="fineAmount" :collapsed="true" action-collapse>
    <b-row>
      <b-col md="4" lg="4" xs="4">
        <h5 class="text-capitalize">Fine Policy</h5>
        <template>
          <div>
            <v-select
              id="fine"
              placeholder="Search by fine policies"
              v-model="finePolicyIds"
              :options="finePolicyOptions"
              :reduce="(item) => item?.id"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              @input="preUpdateFinePolicy(finePolicyIds)"
              :disabled="payslip?.status === 'finally_generated'"
            >
            </v-select>
          </div>
        </template>
      </b-col>

      <b-col md="5" lg="5" xs="5">
        <h5 class="text-capitalize">Fine Policies Details</h5>

        <div v-for="(value, index) in finePolicyData" :key="index">
          <h6 class="text-capitalize">Fine Policy: {{ index + 1 }}</h6>

          <h6 style="display: inline">Title:</h6>
          <p style="display: inline"> {{ value.title }}</p>

          <h6 v-if="value.amount_value_type == 'flat'">
            Amount Value:
            <span class="font-resize">{{ value.amount_value }}</span>
          </h6>

          <h6 v-else>
            Amount Value:
            <span class="font-resize">{{ value.amount_value }}%</span>
          </h6>
        </div>
      </b-col>

      <b-col md="3" lg="3" xs="3">
        <h5 class="text-capitalize">Fine Amount (-)</h5>
        <template>
          <div>
            <b-form-input
              id="fine_amount"
              type="number"
              v-model="fineAmount"
              placeholder="Fine Amount"
              :disabled="true"
            />
          </div>
        </template>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BBadge,
  BFormInput,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "OthersFine",
  components: {
    BCardActions,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BFormInput,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      finePolicyIds: [],
      finePolicyOptions: [],
      fineAmount: 0,
      finePolicyData: [],
    }
  },
  watch: {
    finePolicyIds(newVal) {
      this.preUpdateFinePolicy(newVal)
      this.emitValue()
    },
  },
  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    emitValue() {
      this.$emit('append-data', this.finePolicyIds)
    },

    async getFinePolicyItems() {
      return await this.$api.get("api/fine-policy/all");
    },

    async loadItems() {
      try {
        const [finePoliciesResponse] = await Promise.all([
          this.getFinePolicyItems(),
        ])

        // Fine Policy
        const finePolicies = finePoliciesResponse?.data?.data || [];

        this.finePolicyOptions = finePolicies.map((item) => ({
          name: item.title,
          id: item.id,
        }));

        // Fine Amount
        this.fineAmount = this.payslip.general_fine_amount
        this.finePolicyIds = JSON.parse(this.payslip?.fine_policies)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async preUpdateFinePolicy(finePolicyIds) {
      if (finePolicyIds) {
        const response = await this.$api.put(
          `/api/payroll/${this.$route.params.userId}/fine-policy/pre-update-calculation`,
          {
            fine_policies: finePolicyIds,
            payslip_id: this.payslip.id,
          }
        );

        this.finePolicyData =
          response?.data?.total_amount?.original?.fine_policy_data;

        this.fineAmount = response?.data?.total_amount?.original?.total_amount;
      }
    },
  },
};
</script>

<style scoped>
</style>
