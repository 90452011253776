var render = function () {
  var _vm$payslip, _vm$payslip2, _vm$payslip4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Extra Leave",
      "amount": _vm.extraLeaveAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status === 'finally_generated'
    },
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "6"
    }
  }, [_c('div', [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.typeStatus) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": _vm.isManualSwitch,
      "name": "check-button",
      "switch": "",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated'
    },
    on: {
      "change": _vm.typeChange
    }
  })], 1), _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Edit Amount ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "extra_leave_amount_value",
      "type": "number",
      "placeholder": "Extra Leave Amount",
      "disabled": !_vm.isManualSwitch || ((_vm$payslip2 = _vm.payslip) === null || _vm$payslip2 === void 0 ? void 0 : _vm$payslip2.status) === 'finally_generated'
    },
    model: {
      value: _vm.extraLeaveAmount,
      callback: function callback($$v) {
        _vm.extraLeaveAmount = $$v;
      },
      expression: "extraLeaveAmount"
    }
  })], 1)], _vm.isManualSwitch ? [_c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "id": "unpaid_remarks"
    }
  }, [_vm._v(" Extra Leave Remarks "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', [_c('ValidationProvider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": _vm.isManualSwitch ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$payslip3;
        var errors = _ref.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Remarks",
            "rows": "1",
            "disabled": ((_vm$payslip3 = _vm.payslip) === null || _vm$payslip3 === void 0 ? void 0 : _vm$payslip3.status) === 'finally_generated'
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        })];
      }
    }], null, false, 771348980)
  })], 1)] : _vm._e()], 2), _vm.isManualSwitch ? _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }) : _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }, [[_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Extra Leave Days Count: " + _vm._s((_vm$payslip4 = _vm.payslip) === null || _vm$payslip4 === void 0 ? void 0 : _vm$payslip4.extra_leave_day_count) + " ")])]], 2), _c('b-col', {
    attrs: {
      "offset": "1",
      "md": "3",
      "lg": "3",
      "xs": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Extra Leave Fine Amount ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "extra_leave_amount_value",
      "type": "number",
      "placeholder": "",
      "disabled": true
    },
    model: {
      value: _vm.extraLeaveAmount,
      callback: function callback($$v) {
        _vm.extraLeaveAmount = $$v;
      },
      expression: "extraLeaveAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }