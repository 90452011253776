<template>
  <b-card-actions title="Insurance" :currency="currency" :amount="insuranceAmount" :collapsed="true" action-collapse>
    <b-row>
      <b-col md="4" lg="4" xs="4">
      </b-col>

      <b-col md="5" lg="5" xs="5">
        <h5 class="text-capitalize">Insurance Policy Details</h5>
            <div>
              <h6 class="text-capitalize" style="display: inline">Title:</h6>
              <p style="display: inline">
                {{ insurancePolicyDetailsData?.title }}
              </p>
            </div>

            <div v-if="insurancePolicyDetailsData?.amount_value_type == 'flat'">
              <h6 class="text-capitalize" style="display: inline">
                Amount Value:
              </h6>
              <p style="display: inline">
                {{ insurancePolicyDetailsData?.amount_value }}
              </p>
            </div>

            <div v-else>
              <h6 class="text-capitalize" style="display: inline">
                Amount Value:
              </h6>
              <p style="display: inline">
                {{ insurancePolicyDetailsData?.amount_value }} % of gross salary
              </p>
            </div>

            <div>
              <h6 class="text-capitalize" style="display: inline">
                Total Months:
              </h6>
              <p style="display: inline">
                {{ insurancePolicyDetailsData?.month }}
              </p>
            </div>

      </b-col>

      <b-col md="3" lg="3" xs="3">
        <h5 class="text-capitalize">Insurance Amount</h5>


        <template>
              <div>
                  <b-form-input
                    id="insurance_amount"
                    type="number"
                    v-model="insuranceAmount"
                    placeholder="Insurance Amount"
                    :disabled="true"
                  />
              </div>
            </template>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BBadge,
  BFormInput,
} from "bootstrap-vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'InsuranceInfo',
  components: {
    BCardActions,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BFormInput,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      insuranceAmount: 0,
      insurancePolicyDetailsData: "",

    };
  },

  watch: {
    payslip(){
      //Insurance amount
      this.insuranceAmount = this.payslip.insurance_amount;
      this.loadPolicyData(this.payslip?.insurance_policy_id);
    }
  },

  methods: {
    async getInsurancePolicyItems(id) {
      return await this.$api.get(`api/insurance-policies/${id}`);
    },

    async loadPolicyData(assignedPolicyId) {
      try {

        // insurance policy data
        if (assignedPolicyId) {
          const insurancePolicy = await this.getInsurancePolicyItems(
            assignedPolicyId
          );

          this.insurancePolicyDetailsData = insurancePolicy?.data?.data;
        }



      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
}
</script>

<style scoped>
</style>
