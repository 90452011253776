<template>
  <b-card-actions
    title="KPI Commission"
    :amount="kpiCommissionAmount"
    :currency="currency"
    :collapsed="true"
    action-collapse
  >

    <b-form>
      <b-row>
        <b-col
          cols="4"
          :class="{ 'disabled': payslip && payslip.status === 'finally_generated' }"
        >
          <h5 class="text-capitalize">
            KPI Commission Amount Input
          </h5>
          <template>
            <div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Commission amount"
                vid="commission_amount"
                rules="required|min:0"
              >
                <b-form-input
                  id="kpi_commission_amount"
                  v-model="kpiCommissionAmount"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :disabled="payslip?.status === 'finally_generated'"
                  placeholder="Kpi Commission Amount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </template>
        </b-col>
        <b-col
          offset="5"
          cols="3"
        >
          <h5 class="text-capitalize">
          KPI Commission Amount
          </h5>
          <template>
            <div>
            <b-form-input
                  id="kpi_commission_amount"
                  v-model="kpiCommissionAmount"
                  type="number"
                  placeholder="Kpi Commission Amount"
                  :disabled="true"
            />
            </div>
          </template>
      </b-col>
      </b-row>
    </b-form>

  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCol, BForm, BFormInput, BRow,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'KPICommissionInfo',
  components: {
    ValidationProvider,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAllSection: false,
      kpiCommissionAmount: 0,
    }
  },
  watch: {
    kpiCommissionAmount() {
      this.emitValue()
    },
    payslip() {
      this.kpiCommissionAmount = this.payslip.kpi_commission_amount
    },
  },
  methods: {
    emitValue() {
      this.$emit('append-data', this.kpiCommissionAmount)
    },
  },
}
</script>

<style scoped>
</style>
