<template>
  <b-card-actions title="Provident Fund" :currency="currency" :amount="providentFundAmount" :collapsed="true" action-collapse>
      <b-row>
        <b-col md="4" lg="4" xs="4">
        </b-col>

        <b-col md="5" lg="5" xs="5">
          <h5 class="text-capitalize">Provident Fund Policy Details</h5>
              <div>
                <h6 class="text-capitalize" style="display: inline">Title:</h6>
                <p style="display: inline">
                  {{ providentPolicyDetailsData?.title }}
                </p>
              </div>

              <div v-if="providentPolicyDetailsData?.amount_value_type == 'flat'">
                <h6 class="text-capitalize" style="display: inline">
                  Amount Value:
                </h6>
                <p style="display: inline">
                  {{ providentPolicyDetailsData?.amount_value }}
                </p>
              </div>

              <div v-else>
                <h6 class="text-capitalize" style="display: inline">
                  Amount Value:
                </h6>
                <p style="display: inline">
                  {{ providentPolicyDetailsData?.amount_value }} % of gross salary
                </p>
              </div>

              <div>
                <h6 class="text-capitalize" style="display: inline">
                  Total Month:
                </h6>
                <p style="display: inline">
                  {{ providentPolicyDetailsData?.month }}
                </p>
              </div>


        </b-col>

        <b-col md="3" lg="3" xs="3">
          <h5 class="text-capitalize">Provident Fund Amount</h5>


          <template>
                <div>
                    <b-form-input
                      id="provident_fund_amount"
                      type="number"
                      v-model="providentFundAmount"
                      placeholder="Provident Fund Amount"
                      :disabled="true"
                    />
                </div>
              </template>
        </b-col>
      </b-row>
    </b-card-actions>
  </template>

  <script>
  import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BFormInput,
  } from "bootstrap-vue";
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    name: 'ProvidentFundInfo',
    components: {
      BCardActions,
      BRow,
      BCol,
      BCard,
      BCardBody,
      BTableLite,
      BCardText,
      BButton,
      BAlert,
      BLink,
      BBadge,
      BFormInput,
    },
    props: {
      payslip: {
        type: Object,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        providentFundAmount: 0,
        providentPolicyDetailsData: "",

      };
    },

    watch: {
    payslip(){
      //provident-funds amount
      this.providentFundAmount = this.payslip.provident_fund_amount;
      this.loadPolicyData(this.payslip?.provident_fund_policy_id);

    }
  },


    methods: {
      async getProvidentFundPolicyItems(id) {
        return await this.$api.get(`api/provident-funds/${id}`);
      },

      async loadPolicyData(assignedPolicyId) {
        try {
          // provident-funds policy data
          if (assignedPolicyId) {
            const providentFundPolicy = await this.getProvidentFundPolicyItems(
              assignedPolicyId
            );

            this.providentPolicyDetailsData = providentFundPolicy?.data?.data;

          }



        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      },
    },
  }
  </script>

  <style scoped>
  </style>
