var render = function () {
  var _vm$paySlipData, _vm$paySlipData2, _vm$paySlipData3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('div', {
    staticClass: "offset-md-1 col-md-10 col-lg-10"
  }, [_c('validation-observer', {
    ref: "payslipValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.payslipValidationForm($event);
      }
    }
  }, [_c('user-info-card', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "user-data": _vm.userData,
      "payslip": _vm.paySlipData
    }
  }), _c('day-counted-salary-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    }
  }), _c('basic-salary-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    }
  }), _c('over-time-salary-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-policy-data": function appendPolicyData($event) {
        return _vm.appendFormData('overtime_policy_id', $event);
      },
      "append-remarks-data": function appendRemarksData($event) {
        return _vm.appendFormData('overtime_remarks', $event);
      },
      "append-isPolicy-data": function appendIsPolicyData($event) {
        return _vm.appendFormData('overtime_is_policy', $event);
      },
      "append-manual-data": function appendManualData($event) {
        return _vm.appendFormData('overtime_manual_amount', $event);
      }
    }
  }), _c('project-commission-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-policy-data": function appendPolicyData($event) {
        return _vm.appendFormData('project_commission_policy_id', $event);
      },
      "append-milestone-data": function appendMilestoneData($event) {
        return _vm.appendFormData('milestone_id', $event);
      },
      "append-remarks-data": function appendRemarksData($event) {
        return _vm.appendFormData('project_commission_remarks', $event);
      },
      "append-isPolicy-data": function appendIsPolicyData($event) {
        return _vm.appendFormData('project_commission_is_policy', $event);
      },
      "append-manual-data": function appendManualData($event) {
        return _vm.appendFormData('project_commission_manual_amount', $event);
      }
    }
  }), _c('k-p-i-commission-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-data": function appendData($event) {
        return _vm.appendFormData('kpi_amount', $event);
      }
    }
  }), _c('festival-bonus-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "user": _vm.userData,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-data": function appendData($event) {
        return _vm.appendFormData('festival_bonus_policies', $event);
      }
    }
  }), _c('unpaid-leave-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-fine-data": function appendFineData($event) {
        return _vm.appendFormData('unpaid_leave_fine_amount', $event);
      },
      "append-remarks-data": function appendRemarksData($event) {
        return _vm.appendFormData('unpaid_leave_remarks', $event);
      },
      "append-isManual-data": function appendIsManualData($event) {
        return _vm.appendFormData('unpaid_leave_is_manual', $event);
      },
      "append-manual-data": function appendManualData($event) {
        return _vm.appendFormData('unpaid_leave_manual_amount', $event);
      }
    }
  }), _c('extra-leave-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-fine-data": function appendFineData($event) {
        return _vm.appendFormData('extra_leave_fine_amount', $event);
      },
      "append-remarks-data": function appendRemarksData($event) {
        return _vm.appendFormData('extra_leave_remarks', $event);
      },
      "append-isManual-data": function appendIsManualData($event) {
        return _vm.appendFormData('extra_leave_is_manual', $event);
      },
      "append-manual-data": function appendManualData($event) {
        return _vm.appendFormData('extra_leave_manual_amount', $event);
      }
    }
  }), _c('others-fine', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-data": function appendData($event) {
        return _vm.appendFormData('fine_policies', $event);
      }
    }
  }), _c('late-fine-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-policy-data": function appendPolicyData($event) {
        return _vm.appendFormData('late_fine_policies_id', $event);
      },
      "append-remarks-data": function appendRemarksData($event) {
        return _vm.appendFormData('late_fine_remarks', $event);
      },
      "append-isPolicy-data": function appendIsPolicyData($event) {
        return _vm.appendFormData('late_fine_is_policy', $event);
      },
      "append-manual-data": function appendManualData($event) {
        return _vm.appendFormData('late_fine_manual_amount', $event);
      }
    }
  }), _c('meal-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-policy-data": function appendPolicyData($event) {
        return _vm.appendFormData('meal_policy_id', $event);
      },
      "append-remarks-data": function appendRemarksData($event) {
        return _vm.appendFormData('meal_remarks', $event);
      },
      "append-isPolicy-data": function appendIsPolicyData($event) {
        return _vm.appendFormData('meal_is_policy', $event);
      },
      "append-manual-data": function appendManualData($event) {
        return _vm.appendFormData('meal_manual_amount', $event);
      },
      "append-meal-data": function appendMealData($event) {
        return _vm.appendFormData('meal_amount', $event);
      }
    }
  }), _c('Loan-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    }
  }), _c('insurance-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    }
  }), _c('provident-fund-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    }
  }), _c('income-tax-info', {
    attrs: {
      "currency": _vm.defaultCurrency,
      "payslip": _vm.paySlipData
    },
    on: {
      "append-data": function appendData($event) {
        return _vm.appendFormData('income_tax_amount', $event);
      }
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.isSubmitting || ((_vm$paySlipData = _vm.paySlipData) === null || _vm$paySlipData === void 0 ? void 0 : _vm$paySlipData.status) === 'finally_generated'
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Save ")], 1), _vm.isPayslipPdfGenerating ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "danger",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), ((_vm$paySlipData2 = _vm.paySlipData) === null || _vm$paySlipData2 === void 0 ? void 0 : _vm$paySlipData2.status) !== 'finally_generated' ? _c('span', [_vm._v("Generating ..")]) : _c('span', [_vm._v("Downloading")])], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.downloadPayslipPDF
    }
  }, [((_vm$paySlipData3 = _vm.paySlipData) === null || _vm$paySlipData3 === void 0 ? void 0 : _vm$paySlipData3.status) !== 'finally_generated' ? _c('span', [_vm._v("Finalize And Generate PDF")]) : _c('span', [_vm._v("Download Payslip")])])]], 2), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h2', {
    staticClass: "text-capitalize text-right"
  }, [_vm._v(" Total: " + _vm._s(_vm.defaultCurrency) + " " + _vm._s(_vm.totalPayableAmount) + " ")])])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }