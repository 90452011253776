var render = function () {
  var _vm$payslip, _vm$payslip2, _vm$payslip3, _vm$overtimeSalaryPol, _vm$overtimeSalaryPol2, _vm$overtimeSalaryPol3, _vm$overtimeSalaryPol4, _vm$overtimeSalaryPol5, _vm$overtimeSalaryPol6, _vm$overtimeSalaryPol7, _vm$overtimeSalaryPol8, _vm$overtimeSalaryPol9, _vm$overtimeSalaryPol10, _vm$overtimeSalaryPol11, _vm$overtimeSalaryPol12, _vm$overtimeSalaryPol13, _vm$overtimeSalaryPol14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Overtime Salary",
      "amount": _vm.overTimeSalaryAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status == 'finally_generated'
    },
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "6"
    }
  }, [_c('div', [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.oTSalaryInputStatus) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": _vm.isPolicySwitch,
      "name": "check-button",
      "switch": "",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated'
    },
    on: {
      "change": function change(newVal) {
        return _vm.onSwitchChange(newVal);
      }
    }
  })], 1), _vm.oTSalaryInputStatus === 'Policy' ? _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" OverTime Salary Policy ")]) : _vm._e(), _vm.oTSalaryInputStatus === 'Policy' ? [_c('div', [_c('v-select', {
    attrs: {
      "id": "policyId",
      "options": _vm.overTimeSalaryPolicyOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "placeholder": "Choose Here",
      "disabled": ((_vm$payslip2 = _vm.payslip) === null || _vm$payslip2 === void 0 ? void 0 : _vm$payslip2.status) === 'finally_generated'
    },
    model: {
      value: _vm.overTimeSalaryPolicyId,
      callback: function callback($$v) {
        _vm.overTimeSalaryPolicyId = $$v;
      },
      expression: "overTimeSalaryPolicyId"
    }
  })], 1)] : _vm._e(), !_vm.isPolicySwitch ? [_vm.oTSalaryInputStatus === 'Manual' ? _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Manual OverTime Salary ")]) : _vm._e(), _vm.oTSalaryInputStatus === 'Manual' ? [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "working_day_amount_value",
      "type": "number",
      "disabled": ((_vm$payslip3 = _vm.payslip) === null || _vm$payslip3 === void 0 ? void 0 : _vm$payslip3.status) === 'finally_generated',
      "placeholder": "Overtime Amount"
    },
    model: {
      value: _vm.editOverTimeSalaryAmount,
      callback: function callback($$v) {
        _vm.editOverTimeSalaryAmount = $$v;
      },
      expression: "editOverTimeSalaryAmount"
    }
  })], 1)] : _vm._e(), _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "id": "project_commission"
    }
  }, [_vm._v(" OverTime Salary Remarks "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), [_c('div', [_c('ValidationProvider', {
    attrs: {
      "name": "Overtime remarks",
      "vid": "ovtimeremarks",
      "rules": !_vm.isPolicySwitch ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$payslip4;
        var errors = _ref.errors;
        return [_c('b-form-textarea', {
          staticClass: "required-label",
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Remarks",
            "disabled": ((_vm$payslip4 = _vm.payslip) === null || _vm$payslip4 === void 0 ? void 0 : _vm$payslip4.status) === 'finally_generated',
            "rows": "1"
          },
          model: {
            value: _vm.overTimeSalaryRemarksText,
            callback: function callback($$v) {
              _vm.overTimeSalaryRemarksText = $$v;
            },
            expression: "overTimeSalaryRemarksText"
          }
        })];
      }
    }], null, false, 381947556)
  })], 1)]] : _vm._e()], 2), _vm.oTSalaryInputStatus === 'Manual' ? _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }) : _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize mt-2"
  }, [_vm._v(" Overtime Salary Policy Details ")]), _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Off day amount value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [((_vm$overtimeSalaryPol = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol === void 0 ? void 0 : _vm$overtimeSalaryPol.off_day_amount_value_type) === 'multiplied' ? _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol2 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol2 === void 0 ? void 0 : _vm$overtimeSalaryPol2.off_day_amount_value) + " * Hourly salary ")]) : ((_vm$overtimeSalaryPol3 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol3 === void 0 ? void 0 : _vm$overtimeSalaryPol3.off_day_amount_value_type) === 'extra_added_with_monthly_salary' ? _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol4 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol4 === void 0 ? void 0 : _vm$overtimeSalaryPol4.off_day_amount_value) + " + Monthly salary ")]) : ((_vm$overtimeSalaryPol5 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol5 === void 0 ? void 0 : _vm$overtimeSalaryPol5.off_day_amount_value_type) === 'extra_added_with_hourly_rate' ? _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol6 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol6 === void 0 ? void 0 : _vm$overtimeSalaryPol6.off_day_amount_value) + " + Hourly salary ")]) : _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol7 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol7 === void 0 ? void 0 : _vm$overtimeSalaryPol7.off_day_amount_value) + " ")])])]), _c('div', [_c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Working day amount value: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [((_vm$overtimeSalaryPol8 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol8 === void 0 ? void 0 : _vm$overtimeSalaryPol8.working_day_amount_value_type) === 'multiplied' ? _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol9 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol9 === void 0 ? void 0 : _vm$overtimeSalaryPol9.working_day_amount_value) + " * Hourly salary ")]) : ((_vm$overtimeSalaryPol10 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol10 === void 0 ? void 0 : _vm$overtimeSalaryPol10.working_day_amount_value_type) === 'extra_added_with_monthly_salary' ? _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol11 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol11 === void 0 ? void 0 : _vm$overtimeSalaryPol11.working_day_amount_value) + " + Monthly salary ")]) : ((_vm$overtimeSalaryPol12 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol12 === void 0 ? void 0 : _vm$overtimeSalaryPol12.working_day_amount_value_type) === 'extra_added_with_hourly_rate' ? _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol13 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol13 === void 0 ? void 0 : _vm$overtimeSalaryPol13.working_day_amount_value) + " + Hourly salary ")]) : _c('span', [_vm._v(" " + _vm._s((_vm$overtimeSalaryPol14 = _vm.overtimeSalaryPolicyDetailsData) === null || _vm$overtimeSalaryPol14 === void 0 ? void 0 : _vm$overtimeSalaryPol14.working_day_amount_value) + " ")])])])]), _c('b-col', {
    attrs: {
      "offset": "1",
      "md": "3",
      "lg": "3",
      "xs": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" OverTime Salary ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "working_day_amount_value",
      "type": "number",
      "placeholder": "Overtime Salary",
      "disabled": true
    },
    model: {
      value: _vm.overTimeSalaryAmount,
      callback: function callback($$v) {
        _vm.overTimeSalaryAmount = $$v;
      },
      expression: "overTimeSalaryAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }