<template>
  <b-card-actions
    title="Late Fine"
    :amount="LateFineAmount"
    :currency="currency"
    :collapsed="true"
    action-collapse
  >
    <b-row>
      <b-col
        md="4"
        lg="4"
        xs="6"
        :class="{ 'disabled': payslip && payslip.status === 'finally_generated' }"
      >
        <div>
          <b-card-text class="mb-0">
            {{ typeStatus }}
          </b-card-text>

          <b-form-checkbox
            :checked="isPolicySwitch"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="typeChange"
            :disabled="payslip?.status === 'finally_generated'"
          />
        </div>

        <h5
          v-if="typeStatus === 'Manual'"
          class="text-capitalize"
          style="margin-top: 20px"
        >
          Edit Late Fine Amount
        </h5>
        <template v-if="!isPolicySwitch">
          <div>
            <ValidationProvider
              v-slot="{ errors }"
              name="Late Fine amount"
              vid="manual_amount_value"
              rules="numeric_or_float"
            >
              <b-form-input
                id="manual_amount_value"
                v-model="LateFineAmount"
                :state="errors.length > 0 ? false : null"
                placeholder="Late Fine Amount"
                :disabled="payslip?.status === 'finally_generated'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

          </div>

          <h5
            id="lateFine"
            class="text-capitalize"
            style="margin-top: 20px"
          >
            Late Fine Remarks <span class="text-danger">*</span>
          </h5>
          <template>
            <div>
              <ValidationProvider
                v-slot="{ errors }"
                name="remarks"
                vid="remarks"
                :rules="!isPolicySwitch ? 'required' : ''"
              >
                <b-form-textarea
                  id="remarks"
                  v-model="remarks"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Remarks"
                  :disabled="payslip?.status === 'finally_generated'"
                  rows="1"
                />
              </ValidationProvider>
            </div>
          </template>
        </template>

        <h5
          v-if="typeStatus === 'Policy'"
          class="text-capitalize"
          style="margin-top: 20px"
        >
          Late Fine Policy
        </h5>
        <template v-if="typeStatus === 'Policy'">
          <div>
            <v-select
              id="lateFinePolicyId"
              v-model="lateFinePolicyId"
              :options="lateFinePolicyOptions"
              :reduce="(option) => option.id"
              label="name"
              placeholder="Choose Here"
              :disabled="payslip?.status === 'finally_generated'"
              multiple
            />
          </div>
        </template>
      </b-col>

      <b-col
        v-if="typeStatus === 'Manual'"
        md="4"
        lg="4"
        xs="5"
      />

      <b-col
        v-else
        md="4"
        lg="4"
        xs="5"
      >
        <h5 class="text-capitalize">
          Late Fine Policy Details
        </h5>

        <div v-if="policyDetailsData?.delay">
          <h6
            class="text-capitalize"
            style="display: inline"
          >
            Delay Fine:
          </h6>
          <p style="display: inline">
            {{ policyDetailsData?.delay }}
          </p>
        </div>

        <div v-if="policyDetailsData?.extreme_delay">
          <h6
            class="text-capitalize"
            style="display: inline"
          >
            Extreme Delay Fine:
          </h6>
          <p style="display: inline">
            {{ policyDetailsData?.extreme_delay }}
          </p>
        </div>
      </b-col>

      <b-col
          class="align-content-end"
        offset="1"
        md="3"
        lg="3"
        xs="6"
      >
        <h5 class="text-capitalize">
          Late Fine Amount
        </h5>
        <template>
          <div>
            <b-form-input
              id="manual_amount_value"
              v-model="LateFineAmount"
              type="number"
              placeholder="Late Fine"
              :disabled="true"
            />
          </div>
        </template>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCardText, BCol, BFormCheckbox, BFormInput, BFormTextarea, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, extend } from 'vee-validate'

extend('numeric_or_float', {
  validate(value) {
    return /^[0-9]*\.?[0-9]+$/.test(value)
  },
  message: 'The {_field_} field must be a valid number or decimal.'
})

import {
  required,
  numeric,
  email,
  max,
  mimes,
  ext,
  size,
  confirmed,
} from "@validations";

export default {
  name: 'LateFineInfo',
  components: {
    ValidationProvider,
    BFormInput,
    BFormTextarea,
    BCardText,
    BCol,
    BRow,
    BFormCheckbox,
    BCardActions,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      typeStatus: 'Policy',
      isPolicySwitch: true,
      policyChangeCount: 0,
      LateFineAmount: 0,
      lateFinePolicyId: '',
      lateFinePolicyOptions: [],
      storeLateFinePolicyOptions: [],
      remarks: '',
      policyDetailsData: '',
    }
  },
  watch: {
    lateFinePolicyId() {
      this.preUpdateLateFinePolicy()
      this.handlePolicyValidation()

      this.emitValue()

      if (this.policyChangeCount > 0) {
        this.remarks = ''
      } else {
        // eslint-disable-next-line no-plusplus
        this.policyChangeCount++
      }
    },
    LateFineAmount() {
      this.emitValue()
    },
    isPolicySwitch() {
      this.emitValue()
    },
    remarks() {
      this.emitValue()
    },
  },
  async created() {
    try {
      await this.loadItems()
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },
  methods: {
    emitValue() {
      this.$emit('append-policy-data', this.lateFinePolicyId)
      this.$emit('append-remarks-data', this.remarks)
      this.$emit('append-isPolicy-data', this.isPolicySwitch)
      this.$emit('append-manual-data', this.LateFineAmount)
    },
    handlePolicyValidation() {
      this.lateFinePolicyOptions = this.storeLateFinePolicyOptions

      const selectedOptions = this.lateFinePolicyOptions.filter(option => this.lateFinePolicyId.includes(option.id))

      // Extract unique late_types from the selected options
      const selectedLateTypes = [...new Set(selectedOptions.map(option => option.late_type))]

      // Filter lateFinePolicyOptions based on selectedLateTypes
      this.lateFinePolicyOptions = this.lateFinePolicyOptions.filter(option => {
        // Check if the option's id is in the selected array
        if (this.lateFinePolicyId.includes(option.id)) {
          return true
        }
        // Check if the option's late_type is in the selectedLateTypes array
        return !selectedLateTypes.includes(option.late_type)
      })
    },
    typeChange() {
      this.isPolicySwitch = !this.isPolicySwitch
      if (this.typeStatus === 'Policy') {
        this.typeStatus = 'Manual'
      } else {
        this.typeStatus = 'Policy'
        this.lateFinePolicyId = JSON.parse(this.payslip.delay_fine_policy_id)
      }
    },
    async loadItems() {
      try {

        const [delayPolicies] = await Promise.all([
          this.getDelayFinePolicyItems(),
        ])

        // Late Fine policy
        this.LateFineAmount = this.payslip?.delay_fine_amount
        this.lateFinePolicyId = JSON.parse(this.payslip.delay_fine_policy_id)

        this.remarks = this.payslip?.late_fine_remarks

        if (this.remarks) {
          this.isPolicySwitch = false
          this.typeStatus = 'Manual'
          this.LateFineAmount = this.payslip?.delay_fine_amount
        }

        // after update remark don't go
        this.policyChangeCount = 0

        this.lateFinePolicyOptions = (
            delayPolicies?.data?.data || []
        ).map(item => ({
          name: item.title,
          late_type: item.late_type,
          id: item.id,
        }))

        this.storeLateFinePolicyOptions = this.lateFinePolicyOptions
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    async getDelayFinePolicyItems() {
      return this.$api.get('api/late-fine-policy/all')
    },

    async preUpdateLateFinePolicy() {
      if (this.lateFinePolicyId) {
        const response = await this.$api.put(
          `/api/payroll/${this.$route.params.userId}/late-fine-policy/pre-update-calculation`,
          {
            policies_id: this.lateFinePolicyId,
            payslip_id: this.payslip?.id,
          },
        )

        this.LateFineAmount = response?.data?.total_amount?.original?.total_amount

        this.policyDetailsData = response?.data?.total_amount?.original?.data
      }
    },
  },
}
</script>

<style scoped>
</style>
