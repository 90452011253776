var render = function () {
  var _vm$userData, _vm$userData2, _vm$userData3, _vm$userData4, _vm$userData5, _vm$userData5$departm, _vm$userData5$departm2, _vm$payslip;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" User Info ")])])], 1), _c('b-row', [_c('div', {
    staticClass: "col-md-3 col-lg-3"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$userData = _vm.userData) === null || _vm$userData === void 0 ? void 0 : _vm$userData.avatar,
      "size": "104px",
      "rounded": ""
    }
  })], 1), _c('div', {
    staticClass: "col-md-3 col-lg-3"
  }, [_c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Name ")]), _c('p', [_vm._v(_vm._s((_vm$userData2 = _vm.userData) === null || _vm$userData2 === void 0 ? void 0 : _vm$userData2.name))]), _c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Email ")]), _c('p', [_vm._v(_vm._s((_vm$userData3 = _vm.userData) === null || _vm$userData3 === void 0 ? void 0 : _vm$userData3.email))]), _c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Mobile ")]), _c('p', [_vm._v(_vm._s((_vm$userData4 = _vm.userData) === null || _vm$userData4 === void 0 ? void 0 : _vm$userData4.mobile))])]), _c('div', {
    staticClass: "col-md-3 col-lg-3"
  }, [_c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Department ")]), _c('p', [_vm._v(_vm._s((_vm$userData5 = _vm.userData) === null || _vm$userData5 === void 0 ? void 0 : (_vm$userData5$departm = _vm$userData5.department) === null || _vm$userData5$departm === void 0 ? void 0 : (_vm$userData5$departm2 = _vm$userData5$departm.data) === null || _vm$userData5$departm2 === void 0 ? void 0 : _vm$userData5$departm2.name))]), _c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Gross Salary ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.currency) + " " + _vm._s(_vm.payslip.gross_salary) + " ")]), _c('h5', {
    staticClass: "text-capitalize mb-75",
    attrs: {
      "id": "basic_salary"
    }
  }, [_vm._v(" Total Payable Amount ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.currency) + " " + _vm._s(_vm.payslip.total_payable_amount) + " ")])]), _c('div', {
    staticClass: "col-md-3 col-lg-3"
  }, [_c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Payslip Date ")]), _c('p', [_vm._v(_vm._s(_vm.formatDate(_vm.payslip.payslip_date)))]), _c('h5', {
    staticClass: "text-capitalize mb-75"
  }, [_vm._v(" Payslip Status ")]), _c('p', [((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'initially_generated' ? _c('b-badge', {
    staticClass: "mb-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" Initially Generated ")]) : _c('b-badge', {
    staticClass: "mb-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Finally Generated ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }