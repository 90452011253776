<template>
  <b-card-actions
    title="Festival Bonus"
    :amount="festivalBonusPolicyAmount"
    :currency="currency"
    :collapsed="true"
    action-collapse
  >
    <b-form>
      <b-row>
        <b-col
          md="4"
          lg="4"
          xs="6"
          :class="{ 'disabled': payslip && payslip.status === 'finally_generated' }"
        >
          <h5 class="text-capitalize">
            Festival Bonus Policies
          </h5>
          <template>
            <div>
              <v-select
                id="festival"
                v-model="festivalBonusPolicyIds"
                placeholder="Search by festival bonus policies"
                :options="festivalBonusPolicyOptions"
                :reduce="(item) => item?.id"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :disabled="payslip?.status === 'finally_generated' || festivalBonusEligibilityStatus"
              />
            </div>
          </template>
        </b-col>

        <b-col
          md="4"
          lg="4"
          xs="5"
        >
          <h5 class="text-capitalize">
            Festival Bonus Policies Details
          </h5>

          <div
            v-for="(value, index) in festivalBonusPolicyData"
            :key="index"
          >
            <h6 class="text-capitalize">
              Festival Bonus: {{ index + 1 }}
            </h6>

            <h6>
              Title: <span class="font-resize">{{ value.title }}</span>
            </h6>

            <h6 v-if="value.amount_value_type === 'flat'">
              Amount Value:
              <span class="font-resize">{{ value.amount_value }}</span>
            </h6>

            <h6 v-else>
              Amount Value:
              <span class="font-resize">{{ value.amount_value }}%</span>
            </h6>
          </div>
        </b-col>

        <b-col
          offset="1"
          md="3"
          lg="3"
          xs="3"
        >
          <h5 class="text-capitalize">
            Festival Bonus Amount
          </h5>
          <template>
            <div>
              <b-form-input
                id="festival_bonus_policy_amount"
                v-model="festivalBonusPolicyAmount"
                type="number"
                placeholder="Festival Bonus Policy Amount"
                :disabled="true"
              />
            </div>
          </template>
        </b-col>

      </b-row>
    </b-form>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCol, BForm, BFormInput, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FestivalBonusInfo',
  components: {
    BFormInput,
    BForm,
    BRow,
    BCol,
    BCardActions,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      festivalBonusPolicyAmount: 0,
      festivalBonusPolicyData: [],
      festivalBonusPolicyIds: [],
      festivalBonusPolicyOptions: [],
      festivalBonusEligibilityStatus: false,
    }
  },
  watch: {
    festivalBonusPolicyIds() {
      this.preUpdateFestivalBonus()
      this.emitValue()
    },
  },
  async created() {
    try {
      await this.loadItems()
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },
  methods: {
    async loadItems() {
      try {

        const [festivalBonusPolicies] = await Promise.all([
          this.getFestivalBonusPolicyItems(),
        ])

        this.festivalBonusPolicyOptions = (
            festivalBonusPolicies?.data?.data || []
        ).map(item => ({
          name: item.title,
          id: item.id,
        }))

        // Festival Bonus Eligibility
        const eligibilityStartDate = new Date(
            this.user?.festival_bonus_eligibility_count_start
        )
        const eligibilityMonthCount = this.user?.festival_bonus_eligibility_month
        const payslipDate = new Date(this.payslip?.payslip_date)

        this.festivalBonusPolicyAmount = this.payslip?.festival_bonus_policy_amount
        this.festivalBonusPolicyIds = JSON.parse(this.payslip?.festival_bonus_policies)

        await this.getFestivalBonusEligibilityStatus(eligibilityStartDate, eligibilityMonthCount, payslipDate);
      } catch (error) {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    emitValue() {
      this.$emit('append-data', this.festivalBonusPolicyIds)
    },
    async preUpdateFestivalBonus() {
      if (this.festivalBonusPolicyIds) {
        const response = await this.$api.put(
          `/api/payroll/${this.$route.params.userId}/festival-bonus/pre-update-calculation`,
          {
            festival_bonus_policies: this.festivalBonusPolicyIds,
            payslip_id: this.payslip?.id,
          },
        )

        this.festivalBonusPolicyData = response?.data?.total_amount?.original?.festival_bonus_policy_data
        this.festivalBonusPolicyAmount = response?.data?.total_amount?.original?.total_amount
      }
    },
    async getFestivalBonusPolicyItems() {
      return this.$api.get("api/festival-bonus-policy/all")
    },
    async getFestivalBonusEligibilityStatus(eligibilityStartDate, eligibilityMonthCount, payslipDate) {
      // Helper function to convert dates to "YYYY-MM" format
      const formatToYearMonth = (date) => {
        if ((date instanceof Date) && isNaN(date)) {
          return date.toISOString().slice(0, 7)
        }
      }

      try {
        const eligibilityStartYearMonth = formatToYearMonth(new Date(eligibilityStartDate));

        const eligibilityEndDate = new Date(eligibilityStartDate);
        eligibilityEndDate.setMonth(eligibilityEndDate.getMonth() + eligibilityMonthCount);
        const eligibilityEndYearMonth = formatToYearMonth(eligibilityEndDate)

        const payslipYearMonth = formatToYearMonth(new Date(payslipDate))

        // Set festivalBonusEligibilityStatus based on comparison
        this.festivalBonusEligibilityStatus = payslipYearMonth <= eligibilityEndYearMonth;
      } catch (error) {
        this.festivalBonusEligibilityStatus = false
      }
    },
  },
}
</script>

<style scoped>
</style>
