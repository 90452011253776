<template>
  <b-card-actions
    title="Project Commission"
    :amount="projectCommissionSalaryAmount"
    :currency="currency"
    :collapsed="true"
    action-collapse
  >
    <b-row>
      <b-col md="4" lg="4" xs="4">

          <h5 class="text-capitalize">{{ projectCommissionStatus }}</h5>

          <b-form-checkbox
            :checked="switchValue"
            class="custom-control-primary"
            name="check-button"
            switch
            :disabled="payslip?.status === 'finally_generated'"
            @input="onSwitchChange"
          />

        <div v-if="projectCommissionStatus == 'Policy'">
          <h5 class="text-capitalize mt-1">Project Commission Policy</h5>
            <template>
              <div>
                <v-select
                  id="project_commission_policy"
                  v-model="projectCommissionPolicyId"
                  :options="projectCommissionPolicyOptions"
                  :reduce="(option) => option.id"
                  label="name"
                  :disabled="payslip?.status === 'finally_generated'"
                  placeholder="Choose Here"
                />
              </div>
            </template>
        </div>


        <h5 class="text-capitalize" style="margin-top: 20px">Milestones</h5>
        <template>
          <div>
            <v-select
              id="user_id"
              placeholder="Choose Here"
              v-model="milestoneCommissionSalaryIds"
              :options="milestoneCommissionSalaryOptions"
              :reduce="(item) => item?.id"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :disabled="payslip?.status === 'finally_generated'"
              @input="preUpdateProjectCommission(milestoneCommissionSalaryIds)"

            >
            </v-select>
          </div>
        </template>
      </b-col>

      <b-col md="5" lg="5" xs="5">
        <h5 class="text-capitalize">Project Commission Policy Details</h5>
        <div
          v-if="
            projectCommissionPolicyDetailsData?.amount_value_type ==
            'flat'
          "
        >
          <h6 class="text-capitalize" style="display: inline">
            Amount value:
          </h6>
          <p style="display: inline">
            {{ projectCommissionPolicyDetailsData?.amount_value }}
          </p>
        </div>
        <div v-else>
          <h6 class="text-capitalize" style="display: inline">
            Amount value:
          </h6>
          <p style="display: inline">
            {{ projectCommissionPolicyDetailsData?.amount_value }}%
          </p>
        </div>



      </b-col>

      <b-col md="3" lg="3" xs="3">
        <h5 class="text-capitalize">Project Commission Amount</h5>
        <template>
          <div>
            <b-form-input
              id="project_commission_amount"
              type="number"
              v-model="projectCommissionSalaryAmount"
              placeholder="Project Commission Amount"
              :disabled="editProjectCommissionAmount"
            />
          </div>
        </template>

        <div class="mt-1" v-if="projectCommissionStatus == 'Manual'">
          <h5 class="text-capitalize">Manual Value Remarks <span class="text-danger">*</span></h5>

          <template>
            <div>
                <b-form-textarea
                  id="remarks"
                  type="text"
                  v-model="projectCommissionRemarks"
                  placeholder="Remarks"
                  :disabled="payslip?.status === 'finally_generated'"
                  rows="1"
                />
            </div>
          </template>
        </div>



      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BBadge,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  name: "ProjectCommissionInfo",
  components: {
    BCardActions,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,

  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectCommissionPolicyId: "",
      projectCommissionPolicyOptions: [],

      milestoneCommissionSalaryIds: [],
      milestoneCommissionSalaryOptions: [],

      projectCommissionSalaryAmount: 0,
      projectCommissionPolicyDetailsData: "",

      switchValue: true,
      projectCommissionStatus: "Policy",

      editProjectCommissionAmount: true,

      projectCommissionRemarks: "",


    };
  },

  watch: {
    projectCommissionPolicyId() {
      this.preUpdateProjectCommission(this.milestoneCommissionSalaryIds)
      this.emitValue()
    },
    milestoneCommissionSalaryIds() {
      this.emitValue()
    },
    projectCommissionRemarks() {
      this.emitValue()
    },
    switchValue() {
      this.emitValue()
    },
    projectCommissionSalaryAmount() {
      this.emitValue()
    },
    payslip(){
      this.projectCommissionSalaryAmount = this.payslip.project_commission_amount;
      if(this.payslip.project_commission_policy_id){
        this.projectCommissionPolicyId = parseInt(this.payslip.project_commission_policy_id);
      }

      this.milestoneCommissionSalaryIds = JSON.parse(this.payslip.projects);
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    emitValue() {
      this.$emit('append-policy-data', this.projectCommissionPolicyId)
      this.$emit('append-milestone-data', this.milestoneCommissionSalaryIds)
      this.$emit('append-remarks-data', this.projectCommissionRemarks)
      this.$emit('append-isPolicy-data', this.switchValue)
      this.$emit('append-manual-data', this.projectCommissionSalaryAmount)
    },
    async getMilestoneItems() {
      return await this.$api.get("api/milestones/paid/all")
    },
    async getProjectCommissionItems() {
      return await this.$api.get("api/project-commission-policy/all");
    },

    async loadItems() {
      try {
        const [projectCommissionPolicies, milestoneInfo] = await Promise.all([
          this.getProjectCommissionItems(),
          this.getMilestoneItems(),
        ]);

        // Project Commission Policy
        this.projectCommissionPolicyOptions = (
          projectCommissionPolicies?.data?.data || []
        ).map((item) => ({
          name: item.title,
          id: item.id,
        }));

        // Milestone
        this.milestoneCommissionSalaryOptions = (
          milestoneInfo?.data?.data || []
        ).map((item) => ({
          name: item.title,
          id: item.id,
        }));

        //Project Commission Amount
        //this.insuranceAmount = this.payslip.insurance_amount;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async preUpdateProjectCommission(milestoneCommissionSalaryIds) {
      if (this.projectCommissionPolicyId) {
        const payload = {
          project_commission_policy_id: this.projectCommissionPolicyId,
          project_commission_amount: this.editProjectCommissionSalaryAmount,
          payslip_id: this.payslip?.id,
        };

        if (milestoneCommissionSalaryIds) {
          payload.milestone_id = milestoneCommissionSalaryIds;
        }

        const response = await this.$api.put(
          `/api/payroll/${this.$route.params.userId}/project-commission-policy/pre-update-calculation`,
          payload
        );

        const totalAmountData = response?.data?.total_amount?.original;

        this.projectCommissionPolicyDetailsData =
          totalAmountData?.project_commission_policy_data;
        this.projectCommissionSalaryAmount = totalAmountData?.total_amount;
      }else{
        this.projectCommissionPolicyDetailsData = "";
        this.projectCommissionSalaryAmount = 0;
      }
    },

    onSwitchChange() {
      this.switchValue = !this.switchValue
      this.toggleProjectCommissionStatus();
    },

    toggleProjectCommissionStatus() {
      if (this.projectCommissionStatus === "Policy") {
        this.setManualCommissionStatus();
      } else {
        this.setPolicyCommissionStatus();
      }
    },

    setManualCommissionStatus() {
      this.projectCommissionStatus = "Manual";
      this.projectCommissionPolicyId = "";
      this.editProjectCommissionAmount = false;
    },
    setPolicyCommissionStatus() {
      this.projectCommissionStatus = "Policy";
      this.editProjectCommissionAmount = true;
    }

  },
};
</script>

<style scoped>
</style>
