var render = function () {
  var _vm$payslip;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Others Fine",
      "currency": _vm.currency,
      "amount": _vm.fineAmount,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "4"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Fine Policy")]), [_c('div', [_c('v-select', {
    attrs: {
      "id": "fine",
      "placeholder": "Search by fine policies",
      "options": _vm.finePolicyOptions,
      "reduce": function reduce(item) {
        return item === null || item === void 0 ? void 0 : item.id;
      },
      "label": "name",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "multiple": "",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated'
    },
    on: {
      "input": function input($event) {
        return _vm.preUpdateFinePolicy(_vm.finePolicyIds);
      }
    },
    model: {
      value: _vm.finePolicyIds,
      callback: function callback($$v) {
        _vm.finePolicyIds = $$v;
      },
      expression: "finePolicyIds"
    }
  })], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "5",
      "lg": "5",
      "xs": "5"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Fine Policies Details")]), _vm._l(_vm.finePolicyData, function (value, index) {
    return _c('div', {
      key: index
    }, [_c('h6', {
      staticClass: "text-capitalize"
    }, [_vm._v("Fine Policy: " + _vm._s(index + 1))]), _c('h6', {
      staticStyle: {
        "display": "inline"
      }
    }, [_vm._v("Title:")]), _c('p', {
      staticStyle: {
        "display": "inline"
      }
    }, [_vm._v(" " + _vm._s(value.title))]), value.amount_value_type == 'flat' ? _c('h6', [_vm._v(" Amount Value: "), _c('span', {
      staticClass: "font-resize"
    }, [_vm._v(_vm._s(value.amount_value))])]) : _c('h6', [_vm._v(" Amount Value: "), _c('span', {
      staticClass: "font-resize"
    }, [_vm._v(_vm._s(value.amount_value) + "%")])])]);
  })], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Fine Amount (-)")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "fine_amount",
      "type": "number",
      "placeholder": "Fine Amount",
      "disabled": true
    },
    model: {
      value: _vm.fineAmount,
      callback: function callback($$v) {
        _vm.fineAmount = $$v;
      },
      expression: "fineAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }