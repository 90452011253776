<template>
  <b-row>
    <div class="offset-md-1 col-md-10 col-lg-10">

      <validation-observer ref="payslipValidation">
        <b-form @submit.prevent="payslipValidationForm">

          <user-info-card
            :currency="defaultCurrency"
            :user-data="userData"
            :payslip="paySlipData"
          />
          <day-counted-salary-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
          />
          <basic-salary-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
          />

          <over-time-salary-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-policy-data="appendFormData('overtime_policy_id', $event)"
            @append-remarks-data="appendFormData('overtime_remarks', $event)"
            @append-isPolicy-data="appendFormData('overtime_is_policy', $event)"
            @append-manual-data="appendFormData('overtime_manual_amount', $event)"
          />

          <project-commission-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-policy-data="appendFormData('project_commission_policy_id', $event)"
            @append-milestone-data="appendFormData('milestone_id', $event)"
            @append-remarks-data="appendFormData('project_commission_remarks', $event)"
            @append-isPolicy-data="appendFormData('project_commission_is_policy', $event)"
            @append-manual-data="appendFormData('project_commission_manual_amount', $event)"
          />

          <k-p-i-commission-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-data="appendFormData('kpi_amount', $event)"
          />

          <festival-bonus-info
            :currency="defaultCurrency"
            :user="userData"
            :payslip="paySlipData"
            @append-data="appendFormData('festival_bonus_policies', $event)"
          />

          <unpaid-leave-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-fine-data="appendFormData('unpaid_leave_fine_amount', $event)"
            @append-remarks-data="appendFormData('unpaid_leave_remarks', $event)"
            @append-isManual-data="appendFormData('unpaid_leave_is_manual', $event)"
            @append-manual-data="appendFormData('unpaid_leave_manual_amount', $event)"
          />

          <extra-leave-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-fine-data="appendFormData('extra_leave_fine_amount', $event)"
            @append-remarks-data="appendFormData('extra_leave_remarks', $event)"
            @append-isManual-data="appendFormData('extra_leave_is_manual', $event)"
            @append-manual-data="appendFormData('extra_leave_manual_amount', $event)"
          />

          <others-fine
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-data="appendFormData('fine_policies', $event)"
          />

          <late-fine-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-policy-data="appendFormData('late_fine_policies_id', $event)"
            @append-remarks-data="appendFormData('late_fine_remarks', $event)"
            @append-isPolicy-data="appendFormData('late_fine_is_policy', $event)"
            @append-manual-data="appendFormData('late_fine_manual_amount', $event)"
          />

          <meal-info
            :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-policy-data="appendFormData('meal_policy_id', $event)"
            @append-remarks-data="appendFormData('meal_remarks', $event)"
            @append-isPolicy-data="appendFormData('meal_is_policy', $event)"
            @append-manual-data="appendFormData('meal_manual_amount', $event)"
            @append-meal-data="appendFormData('meal_amount', $event)"
          />

          <Loan-info
              :currency="defaultCurrency"
            :payslip="paySlipData"
          />

          <insurance-info
              :currency="defaultCurrency"
            :payslip="paySlipData"
          />

          <provident-fund-info
              :currency="defaultCurrency"
            :payslip="paySlipData"
          />

          <income-tax-info
              :currency="defaultCurrency"
            :payslip="paySlipData"
            @append-data="appendFormData('income_tax_amount', $event)"
          />

          <b-row>
            <b-col cols="6">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isSubmitting || paySlipData?.status === 'finally_generated'"
              >
                <b-spinner
                  v-if="isSubmitting"
                  small
                />
                Save
              </b-button>

              <template v-if="isPayslipPdfGenerating">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="flex-shrink-0 ml-1"
                  variant="danger"
                  disabled
                >
                  <b-spinner small />
                  <span v-if="paySlipData?.status !== 'finally_generated'">Generating ..</span>
                  <span v-else>Downloading</span>
                </b-button>
              </template>
              <template v-else>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  @click="downloadPayslipPDF"
                >
                  <span v-if="paySlipData?.status !== 'finally_generated'">Finalize And Generate PDF</span>
                  <span v-else>Download Payslip</span>

                </b-button>
              </template>
            </b-col>

            <b-col cols="6">
              <h2 class="text-capitalize text-right">
                Total: {{ defaultCurrency }} {{ totalPayableAmount }}
              </h2>
            </b-col>

          </b-row>

        </b-form>
      </validation-observer>
    </div>
  </b-row>
</template>

<script>
import {
  BButton, BCol,
  BForm,
  BRow, BSpinner,
} from 'bootstrap-vue'
import UserInfoCard from '@/views/admin/payroll-management/payslip/policy/UserInfoCard.vue'
import OverTimeSalaryInfo from '@/views/admin/payroll-management/payslip/policy/OverTimeSalaryInfo.vue'
import BasicSalaryInfo from '@/views/admin/payroll-management/payslip/policy/BasicSalaryInfo.vue'
import DayCountedSalaryInfo from '@/views/admin/payroll-management/payslip/policy/DayCountedSalaryInfo.vue'
import ProjectCommissionInfo from '@/views/admin/payroll-management/payslip/policy/ProjectCommissionInfo.vue'
import KPICommissionInfo from '@/views/admin/payroll-management/payslip/policy/KPICommissionInfo.vue'
import FestivalBonusInfo from '@/views/admin/payroll-management/payslip/policy/FestivalBonusInfo.vue'
import UnpaidLeaveInfo from '@/views/admin/payroll-management/payslip/policy/UnpaidLeaveInfo.vue'
import OthersFine from '@/views/admin/payroll-management/payslip/policy/OthersFine.vue'
import LateFineInfo from '@/views/admin/payroll-management/payslip/policy/LateFineInfo.vue'
import MealInfo from '@/views/admin/payroll-management/payslip/policy/MealInfo.vue'
import InsuranceInfo from '@/views/admin/payroll-management/payslip/policy/InsuranceInfo.vue'
import ProvidentFundInfo from '@/views/admin/payroll-management/payslip/policy/ProvidentFundInfo.vue'
import IncomeTaxInfo from '@/views/admin/payroll-management/payslip/policy/IncomeTaxInfo.vue'
import ExtraLeaveInfo from '@/views/admin/payroll-management/payslip/policy/ExtraLeaveInfo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoanInfo from '@/views/admin/payroll-management/payslip/policy/LoanInfo.vue'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'DevPayslipDetailsView',
  components: {
    BCol,
    ExtraLeaveInfo,
    BButton,
    BSpinner,
    BForm,
    DayCountedSalaryInfo,
    IncomeTaxInfo,
    InsuranceInfo,
    ProvidentFundInfo,
    MealInfo,
    LoanInfo,
    LateFineInfo,
    OthersFine,
    UnpaidLeaveInfo,
    FestivalBonusInfo,
    KPICommissionInfo,
    ProjectCommissionInfo,
    BasicSalaryInfo,
    OverTimeSalaryInfo,
    UserInfoCard,
    BRow,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      defaultCurrency: '',
      paySlipData: {},
      userData: {},
      isSubmitting: false,
      totalPayableAmount: 0,
      formData: {
        currency: '',
        overtime_remarks: '',
        overtime_manual_amount: 0,
        overtime_is_policy: true,
        overtime_policy_id: '',

        meal_remarks: '',
        meal_amount: 0,
        meal_manual_amount: 0,
        meal_is_policy: true,
        meal_policy_id: '',

        late_fine_remarks: '',
        late_fine_manual_amount: 0,
        late_fine_is_policy: true,
        late_fine_policies_id: [],

        unpaid_leave_remarks: '',
        unpaid_leave_manual_amount: 0,
        unpaid_leave_is_manual: false,

        extra_leave_remarks: '',
        extra_leave_manual_amount: 0,
        extra_leave_is_manual: false,

        project_commission_remarks: '',
        project_commission_manual_amount: 0,
        project_commission_is_policy: true,
        project_commission_policy_id: '',
        milestone_id: [],

        festival_bonus_policies: [],
        fine_policies: [],
        kpi_amount: 0,
        income_tax_amount: 0,
      },
      isPayslipPdfGenerating: false,
    }
  },
  async created() {
    try {
      await this.loadItems()
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },
  methods: {
    async getSetting() {
      return this.$api.get('/api/settings?includes=currency')
    },

    saveFile(file) {
      const blob = new Blob([file], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'payslip-report.pdf'
      link.click()
    },

    async loadItems() {
      try {
        const [paySlipInfo, settings] = await Promise.all([
          this.getPayslipItems(),
          this.getSetting(),
        ])

        this.paySlipData = paySlipInfo?.data?.data
        this.totalPayableAmount = this.paySlipData?.total_payable_amount
        this.defaultCurrency = settings.data.data.currency.currency
        this.formData.currency = this.defaultCurrency

        const userInfo = await this.getUser()
        this.userData = userInfo?.data?.data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    appendFormData(field, value) {
      this.formData[field] = value
    },
    async payslipValidationForm() {
      this.$refs.payslipValidation.validate().then(async success => {
        if (success) {
          try {
            this.$refs.payslipValidation.reset()
            this.isSubmitting = true

            await this.$api.put(
              `api/payroll/user/${this.userData.id}/payslip/${this.paySlipData.id}/generate`,
              {
                id: this.paySlipData.id,
                ...this.formData,
              },
            )
            this.isSubmitting = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Payslip submit successfully',
              },
            })

            await this.loadItems()

            // redirect here
          } catch (error) {
            this.isSubmitting = false

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.payslipValidation.setErrors(
                  error?.response?.data?.errors,
              )
            }
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'BellIcon',
              variant: 'warning',
              text: 'Check your input',
            },
          })
        }
      })
    },
    async getPayslipItems() {
      return this.$api.get(
        `api/payroll/payslip/${this.$route.params.id}`,
      )
    },
    async getUser() {
      return this.$api.get(
        `api/users/${this.$route.params.userId}?include=department`,
      )
    },
    updateTotalPayable(amount, type = 'plus') {
      if (type === 'plus') {
        this.totalPayableAmount += amount
      } else {
        this.totalPayableAmount -= amount
      }
    },

    async downloadPayslipPDF() {
      this.isPayslipPdfGenerating = true

      this.payslipPDFDate = this.formatDate(this.paySlipData?.payslip_date)

      const response = await this.$api.get('api/payroll/report/payslip', {
        responseType: 'blob',
        params: {
          payslip_id: this.paySlipData?.id,
        },
      })

      await this.saveFile(response.data)

      this.isPayslipPdfGenerating = false

      this.loadItems()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
