var render = function () {
  var _vm$payslip, _vm$payslip2, _vm$payslip3, _vm$payslip5, _vm$policyDetailsData, _vm$policyDetailsData2, _vm$policyDetailsData3, _vm$policyDetailsData4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "title": "Meal",
      "amount": _vm.mealDeductAmount,
      "currency": _vm.currency,
      "collapsed": true,
      "action-collapse": ""
    }
  }, [_c('b-row', {
    staticClass: "mb-5"
  }, [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status === 'finally_generated'
    },
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Meal Cost ")]), _c('b-form-input', {
    attrs: {
      "id": "meal_amount_value",
      "type": "number",
      "disabled": ((_vm$payslip = _vm.payslip) === null || _vm$payslip === void 0 ? void 0 : _vm$payslip.status) === 'finally_generated',
      "placeholder": "Meal Cost Per Person"
    },
    model: {
      value: _vm.mealAmount,
      callback: function callback($$v) {
        _vm.mealAmount = $$v;
      },
      expression: "mealAmount"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    class: {
      'disabled': _vm.payslip && _vm.payslip.status === 'finally_generated'
    },
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "6"
    }
  }, [_c('div', [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.typeStatus) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": _vm.isPolicySwitch,
      "name": "check-button",
      "switch": "",
      "disabled": ((_vm$payslip2 = _vm.payslip) === null || _vm$payslip2 === void 0 ? void 0 : _vm$payslip2.status) === 'finally_generated'
    },
    on: {
      "change": _vm.typeChange
    }
  })], 1), !_vm.isPolicySwitch ? [_vm.typeStatus === 'Manual' ? _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Edit Meal Amount ")]) : _vm._e(), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "manual_amount_value",
      "type": "number",
      "placeholder": "Meal Amount",
      "disabled": ((_vm$payslip3 = _vm.payslip) === null || _vm$payslip3 === void 0 ? void 0 : _vm$payslip3.status) === 'finally_generated'
    },
    model: {
      value: _vm.mealDeductAmount,
      callback: function callback($$v) {
        _vm.mealDeductAmount = $$v;
      },
      expression: "mealDeductAmount"
    }
  })], 1)], _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "id": "meal"
    }
  }, [_vm._v(" Meal Remarks "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), [_c('div', [_c('ValidationProvider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": !_vm.isPolicySwitch ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$payslip4;
        var errors = _ref.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Remarks",
            "disabled": ((_vm$payslip4 = _vm.payslip) === null || _vm$payslip4 === void 0 ? void 0 : _vm$payslip4.status) === 'finally_generated',
            "rows": "1"
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        })];
      }
    }], null, false, 2714636692)
  })], 1)]] : _vm._e(), _vm.typeStatus === 'Policy' ? _c('h5', {
    staticClass: "text-capitalize",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v(" Meal Policy ")]) : _vm._e(), _vm.isPolicySwitch ? [_c('div', [_c('v-select', {
    attrs: {
      "id": "policyId",
      "options": _vm.policyOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "disabled": ((_vm$payslip5 = _vm.payslip) === null || _vm$payslip5 === void 0 ? void 0 : _vm$payslip5.status) === 'finally_generated',
      "placeholder": "Choose Here"
    },
    model: {
      value: _vm.policyId,
      callback: function callback($$v) {
        _vm.policyId = $$v;
      },
      expression: "policyId"
    }
  })], 1)] : _vm._e()], 2), _vm.typeStatus === 'Manual' ? _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }) : _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "5"
    }
  }, [Object.keys(_vm.policyDetailsData).length > 0 ? [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Meal Policy ")]), _c('h6', {
    staticClass: "text-capitalize",
    staticStyle: {
      "display": "inline"
    }
  }, [_vm._v(" Details: ")]), _c('p', {
    staticStyle: {
      "display": "inline"
    }
  }, [((_vm$policyDetailsData = _vm.policyDetailsData) === null || _vm$policyDetailsData === void 0 ? void 0 : _vm$policyDetailsData.amount_value_type) === 'percentage' ? _c('span', [_vm._v(" " + _vm._s(_vm.mealAmount) + " * " + _vm._s((_vm$policyDetailsData2 = _vm.policyDetailsData) === null || _vm$policyDetailsData2 === void 0 ? void 0 : _vm$policyDetailsData2.amount_value) + "% ")]) : _vm._e(), ((_vm$policyDetailsData3 = _vm.policyDetailsData) === null || _vm$policyDetailsData3 === void 0 ? void 0 : _vm$policyDetailsData3.amount_value_type) === 'flat' ? _c('span', [_vm._v(" " + _vm._s((_vm$policyDetailsData4 = _vm.policyDetailsData) === null || _vm$policyDetailsData4 === void 0 ? void 0 : _vm$policyDetailsData4.amount_value) + " ")]) : _vm._e()])] : _vm._e()], 2), _c('b-col', {
    staticClass: "align-content-end",
    attrs: {
      "offset": "1",
      "md": "3",
      "lg": "3",
      "xs": "6"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Meal Deduct Amount ")]), [_c('div', [_c('b-form-input', {
    attrs: {
      "id": "meal_deduct_value",
      "type": "number",
      "placeholder": "",
      "disabled": true
    },
    model: {
      value: _vm.mealDeductAmount,
      callback: function callback($$v) {
        _vm.mealDeductAmount = $$v;
      },
      expression: "mealDeductAmount"
    }
  })], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }