<template>
  <b-card-actions title="Loan" :currency="currency" :amount="LoanAmount" :collapsed="true" action-collapse>
    <b-row>
      <b-col md="4" lg="4" xs="4">
      </b-col>

      <b-col md="5" lg="5" xs="5">
      </b-col>

      <b-col md="3" lg="3" xs="3">
        <h5 class="text-capitalize">Loan Installment</h5>

        <template>
          <div>
              <b-form-input
                id="basic_salary"
                type="number"
                v-model="LoanAmount"
                placeholder="Loan Salary Amount"
                :disabled="true"
              />
          </div>
        </template>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BBadge,
  BFormInput,
} from "bootstrap-vue";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  name: 'BasicSalaryInfo',
  components: {
    BCardActions,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BFormInput,
  },
  props: {
    payslip: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      LoanAmount: 0,

    };
  },

  watch: {
  payslip(){
    this.LoanAmount = this.payslip.loan_installment_amount;
    }
  },

}
</script>

<style scoped>
</style>
